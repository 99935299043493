import {
  Checkbox, FormControlLabel,
} from '@mui/material'
import terms from 'assets/localize/terms'
import { ButtonPadding, ButtonStyle, SimpleButton } from 'components'
import CustomAutocomplete from 'components/autocomplete/Autocomplete'
import { useAppSelector } from 'hooks'
import {
  useState,
} from 'react'
import { NetworksState } from 'reducers/networks/slices'
import { TrainManagerSocketMessage } from 'types/websocket'
import './CrossingAuthorization.scss'

type Props ={
  id: string;
}

export default function CrossingAuthorization({ id }: Props) {
  const [firstSignal, setFirstSignal] = useState('')
  const [secondSignal, setSecondSignal] = useState('')
  const [condition, setCondition] = useState(false)
  const { objects: networkObjects } = useAppSelector(state => state.networks as NetworksState)
  const { trainWebsocket } = useAppSelector(state => state.snci)

  const sendCrossingAuthorization = () => {
    trainWebsocket?.current?.send(
      JSON.stringify({
        messageType: TrainManagerSocketMessage.crossingAuthorization,
        trainId: id,
        onSight: condition,
        firstSignalName: firstSignal,
        ...(secondSignal && { secondSignalName: secondSignal }),
      }),
    )
    setFirstSignal('')
    setSecondSignal('')
    setCondition(false)
  }

  return (
    <div className="action">
      <h4>{terms.Snci.Panel.Communication.crossingAuthorization}</h4>

      <div className="multiple">

        <CustomAutocomplete
          objects={networkObjects.signals.map(obj => ({ value: obj, label: obj }))}
          value={firstSignal}
          placeholder={terms.Snci.Panel.Communication.firstSignal}
          onValueChange={setFirstSignal}
        />

        <CustomAutocomplete
          disabled={!firstSignal}
          objects={networkObjects.signals.map(obj => ({ value: obj, label: obj }))}
          value={secondSignal}
          placeholder={terms.Snci.Panel.Communication.secondSignal}
          onValueChange={setSecondSignal}
          clearable
        />

      </div>

      <FormControlLabel
        control={(
          <Checkbox
            checked={condition}
            onChange={(_, v) => setCondition(v)}
          />
      )}
        label={terms.Snci.Panel.Communication.underViewCondition}
      />

      <SimpleButton
        className="submit"
        padding={ButtonPadding.small}
        style={ButtonStyle.lightPurple}
        text={terms.Common.give}
        disabled={!firstSignal}
        onClick={sendCrossingAuthorization}
      />

    </div>
  )
}
