import terms from 'assets/localize/terms'
import { LongBeep, ShortBeep } from 'reducers/types'
import {
  FormError, IndexError,
  PKError,
  TwinDetail,
} from 'types/snci'
import { SocketMessage } from 'types/websocket'

const INDEX_RULE = /^[A-Z0-9]{1,6}$/

export const PK_RULE = /^[0-9]{1,3},[0-9]{3}$/

export const isLongBeep = (bp: ShortBeep | LongBeep): bp is LongBeep => bp.messageType === SocketMessage.longBeep
|| bp.messageType === SocketMessage.discordanceBeep || bp.messageType === SocketMessage.transitCancellationBeep

export const isShortBeep = (bp: ShortBeep | LongBeep): bp is ShortBeep => bp.messageType === SocketMessage.shortBeep

export const formatToReadablePk = (pk: string | undefined) => pk?.replace('_', ',')

export const formatToDataPk = (pk: string) => pk.replace(',', '_')

export const trainDetailsNullRepresentation = '-'

export enum ForwardState {
  move = 'MOVE',
  stop = 'STOP',
  unavailable = 'UNAVAILABLE'
}

export const hasPkError = (error: FormError): error is PKError => typeof error === 'object' && 'pk' in error

export const hasIndexError = (error: FormError): error is IndexError => typeof error === 'object' && 'index' in error

export const isCorrectIndex = (error: string) => INDEX_RULE.test(error)

export const getTrainName = (train: {trainIndex: string} & TwinDetail) => {
  if (!train) return ''

  if (train.twin) {
    return terms.Snci.Panel.twinnedTrain
  }

  if (train.untwin) {
    return train.untwinDetail
  }

  return `${terms.Snci.Panel.train}${train.trainIndex}`
}

export const getTrainSubtitle = (train: {trainIndex: string} & TwinDetail) => {
  if (!train) return ''

  if (train.untwin) {
    return train.untwinDetail
  }

  return ''
}

export enum ManeuverButtonState {
  begin = 'BEGIN_MANEUVER',
  continue = 'CONTINUE_MANEUVER',
  unavailable = 'UNAVAILABLE'
}
