import { Checkbox, FormControlLabel } from '@mui/material'
import terms from 'assets/localize/terms'
import { ButtonPadding, ButtonStyle, SimpleButton } from 'components'
import TextInput from 'components/textInput/TextInput'
import {
  formatToDataPk, formatToReadablePk, hasPkError, PK_RULE,
} from 'features/snci/services/snci'
import { useAppDispatch, useAppSelector } from 'hooks'
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import { setFormError } from 'reducers/snci/slices'
import { SocketMessage, TrainManagerSocketMessage } from 'types/websocket'
import './MovementInit.scss'

type Props ={
  id: string;
}

export default function MovementInit({ id }: Props) {
  const dispatch = useAppDispatch()
  const [startPk, setStartPk] = useState('')
  const [endPk, setEndPk] = useState('')
  const [withPk, setWithPk] = useState(false)
  const { trainWebsocket, formError, trainDetails } = useAppSelector(state => state.snci)

  const pkError = (pk: string) => withPk && pk.length && !PK_RULE.test(pk)

  const sendStopRequest = () => {
    if (!withPk) {
      trainWebsocket?.current?.send(
        JSON.stringify({
          messageType: TrainManagerSocketMessage.moving,
          trainId: id,
          consumerSpecific: true,
        }),
      )
      return
    }

    const startVal = formatToDataPk(startPk)
    const endVal = formatToDataPk(endPk)

    trainWebsocket?.current?.send(
      JSON.stringify({
        messageType: TrainManagerSocketMessage.movingWithPk,
        trainId: id,
        consumerSpecific: true,
        beginPK: startVal,
        endPK: endVal,
      }),
    )
  }

  useEffect(() => {
    if (withPk) {
      setStartPk(formatToReadablePk(trainDetails.pk))
    }
  }, [withPk, trainDetails.pk])

  const handlePkMenu = (_: unknown, checked: boolean) => {
    if (checked) {
      trainWebsocket?.current?.send(
        JSON.stringify({
          messageType: SocketMessage.trainManager,
          consumerSpecific: true,
          state: TrainManagerSocketMessage.trainPk,
          trainId: id,
        }),
      )
    } else {
      setStartPk('')
      setEndPk('')
    }
    setWithPk(checked)
  }

  const updateValue = (val: string, setter: Dispatch<SetStateAction<string>>) => {
    if (hasPkError(formError) && formatToReadablePk(formError.pk) === val) {
      dispatch(setFormError(undefined))
    }
    setter(val)
  }

  return (
    <div className="action">
      <h4>{terms.Snci.Panel.Communication.movementInit}</h4>

      <FormControlLabel
        control={(
          <Checkbox
            checked={withPk}
            onChange={handlePkMenu}
          />
)}
        label={terms.Snci.Panel.Communication.underCarefulCondition}
      />

      {withPk && (
      <div className="multiple">
        <TextInput
          value={startPk}
          onChange={v => updateValue(v, setStartPk)}
          disabled={false}
          placeholder={terms.Snci.Panel.Communication.pkPlaceholder}
          error={pkError(startPk) || (hasPkError(formError) && formatToReadablePk(formError?.pk) === startPk)}
          errorMessage={formError && (hasPkError(formError) && formatToReadablePk(formError?.pk))
            ? terms.Snci.Panel.Communication.pkNotExists : terms.Snci.Panel.Communication.pkError}
        />

        <TextInput
          value={endPk}
          onChange={v => updateValue(v, setEndPk)}
          disabled={false}
          placeholder={terms.Snci.Panel.Communication.pkPlaceholder}
          error={pkError(endPk) || (hasPkError(formError) && formatToReadablePk(formError?.pk) === endPk)}
          errorMessage={formError && (hasPkError(formError) && formatToReadablePk(formError?.pk) === endPk)
            ? terms.Snci.Panel.Communication.pkNotExists : terms.Snci.Panel.Communication.pkError}
        />
      </div>

      )}

      <SimpleButton
        className="submit"
        padding={ButtonPadding.small}
        style={ButtonStyle.lightPurple}
        text={terms.Common.give}
        disabled={withPk && ((!startPk || pkError(startPk)) || (!endPk || pkError(endPk)))}
        onClick={sendStopRequest}
      />
    </div>
  )
}
