import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import './RoleSelection.scss'
import SimpleButton, { ButtonStyle } from 'components/button/SimpleButton'
import { CustomRoutes } from 'services/route'

export default function RoleSelection(): ReactElement {
  const navigate = useNavigate()
  return (
    <div className="roles-selection d-flex w-100 h-100 flex-column">
      <SimpleButton
        style={ButtonStyle.primary}
        text="Expérimentateur"
        onClick={() => navigate(CustomRoutes.experimenter)}
      />
      <SimpleButton
        style={ButtonStyle.primary}
        text="Formateur"
        onClick={() => navigate(CustomRoutes.trainer)}
      />

      <SimpleButton
        style={ButtonStyle.primary}
        text="Apprenant"
        onClick={() => navigate(CustomRoutes.learner)}
      />
    </div>
  )
}
