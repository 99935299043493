import { ReactComponent as EarthIcon } from 'assets/icons/earth.svg'
import terms from 'assets/localize/terms'
import { modalSignal } from 'components/modals/wrapper'
import { Dashboard } from 'types/trainer'
import useCopyToClipboard from 'utils/hooks'
import ClosePopup from '../ClosePopup/ClosePopup'
import './RoomTopBar.scss'

type Props = {
  dashboard: Dashboard | undefined;
}
export default function RoomTopBar({ dashboard }: Props) {
  const { copyToClipboard } = useCopyToClipboard()

  const handleClose = () => {
    modalSignal.value = <ClosePopup />
  }

  return (
    <div className="room-bar">
      <h2>{dashboard?.name}</h2>

      <div className="actions">
        <div className="room-info">
          <div>
            <EarthIcon />
            <span>{terms.Common.code}</span>
          </div>
          <button onClick={() => copyToClipboard(dashboard?.code)} type="button" className="code">
            {dashboard?.code}
          </button>
        </div>
        <button onClick={handleClose} type="button" className="close-room">
          {terms.Trainer.closeRoom}
        </button>
      </div>
    </div>
  )
}
