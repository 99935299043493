import { PanelOption } from 'types/panel'
import { ReactComponent as TrainIcon } from 'assets/icons/train.svg'
import { ReactComponent as LeverIcon } from 'assets/icons/lever.svg'
import { ReactComponent as CommandsIcon } from 'assets/icons/commands.svg'
import TrainList from 'features/snci/components/trainList/TrainList'
import InstallationList from 'features/snci/components/installationList/InstallationList'
import AncillaryCommandList from 'features/snci/components/ancillaryCommandList/AncillaryCommandList'

export const PANEL_OPTIONS: PanelOption[] = [{
  name: 'train_list',
  icon: <TrainIcon width={24} height={24} />,
  component: <TrainList />,
},
{
  name: 'lever_list',
  icon: <LeverIcon width={24} height={24} />,
  component: <InstallationList />,
},
{
  name: 'ancillary_commands',
  icon: <CommandsIcon width={18} height={18} />,
  component: <AncillaryCommandList />,
},
]
