import { ReactComponent as PlayIcon } from 'assets/icons/play.svg'
import terms from 'assets/localize/terms'
import { PanelSignal } from 'components'
import TransitCancellation from './transitCancellation/TransitCancellation'

export default function AncillaryCommandList() {
  const openTransitCancellation = () => {
    PanelSignal.value = <TransitCancellation />
  }

  return (
    <>
      <h3 className="panel-title">{terms.Snci.Panel.ancillaryCommands}</h3>

      <div className="panel-content">
        <button
          type="button"
          onClick={openTransitCancellation}
        >
          {terms.Snci.Panel.AncillaryCommands.transitCancellation}
          <div className="info">
            <PlayIcon width={24} height={24} />
          </div>
        </button>

      </div>
    </>
  )
}
