import { LockButton } from 'types/snci'
import './Lock.scss'
import { ButtonPadding, ButtonStyle, SimpleButton } from 'components'
import { ButtonRadius } from 'components/button/SimpleButton'
import { ReactComponent as WarningIcon } from 'assets/icons/error.svg'
import terms from 'assets/localize/terms'
import { InstallationSocketMessage } from 'types/websocket'
import { useAppSelector } from 'utils'

type Props = {
  lock: LockButton;
}
export default function Lock({ lock }: Props) {
  const { installationWebSocket } = useAppSelector(state => state.snci)

  const onClick = () => {
    installationWebSocket?.current?.send(JSON.stringify({
      messageType: InstallationSocketMessage.useLockCanceller,
      automatonName: lock.automatonName,
    }))
  }
  return (
    <div className="lock-canceller">
      <h4>{lock.verboseName}</h4>

      {lock.useState && (
      <div className="used">
        <WarningIcon />
        {terms.Snci.Panel.Installations.usedLock}
      </div>
      )}

      <div className="submission">
        <SimpleButton
          padding={ButtonPadding.xxs}
          style={ButtonStyle.lightPurple}
          radius={ButtonRadius.medium}
          text={terms.Common.use}
          disabled={!lock.visibility}
          onClick={onClick}
        />
      </div>
    </div>
  )
}
