import terms from 'assets/localize/terms'
import { NetworksState } from 'reducers/networks/slices'
import { useAppSelector } from 'utils'
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg'
import { InstallationSocketMessage, SocketMessage } from 'types/websocket'
import { PanelSignal } from 'components'
import { ReactComponent as ChevronIcon } from 'assets/icons/triangle-arrow-left.svg'
import AuthorizationDetails from './authorizationDetails/AuthorizationDetails'
import InstallationList from '../InstallationList'
import './AuthorizationsList.scss'

export default function AuthorizationsList() {
  const { objects: networkObjects } = useAppSelector(state => state.networks as NetworksState)
  const { installationWebSocket } = useAppSelector(state => state.snci)

  const goBack = () => {
    PanelSignal.value = <InstallationList />
  }

  const selectAuthorization = (name: string) => {
    PanelSignal.value = <AuthorizationDetails />
    installationWebSocket?.current?.send(
      JSON.stringify({
        messageType: SocketMessage.installationManager,
        state: InstallationSocketMessage.installationDescription,
        automatonName: name,
        consumerSpecific: true,
      }),
    )
  }
  return (
    <div className="authorization-list">
      <div className="title">
        <div className="go-back">
          <button type="button" onClick={goBack}>
            <ChevronIcon width={24} height={24} />
          </button>
          <h3 className="mode">
            {terms.Snci.Panel.authorizations}
          </h3>
        </div>
      </div>

      <div className="panel-content">
        {networkObjects.authorizations.map(obj => (
          <button
            key={obj.automatonName}
            type="button"
            onClick={() => selectAuthorization(obj.automatonName)}
          >
            {obj.verboseName}
            <div className="info">
              <PlayIcon width={24} height={24} />
            </div>
          </button>
        ))}
      </div>
    </div>
  )
}
