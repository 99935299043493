import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Simulation } from 'reducers/types'
import { TrainerSocketMessage } from 'types/websocket'

export type StudentState = {
  simulation: Simulation; // not exactly a simulation
  stopped: TrainerSocketMessage.closeDashboard;
}

const initialState: StudentState = {
  simulation: undefined,
  stopped: undefined,
}

const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    setSimulation: (state, action: PayloadAction<Simulation>) => {
      state.simulation = action.payload
    },
    setStopReason: (state, action: PayloadAction<TrainerSocketMessage.closeDashboard>) => {
      state.stopped = action.payload
    },
  },
})

export const {
  setSimulation,
  setStopReason,
} = studentSlice.actions

export default studentSlice.reducer
