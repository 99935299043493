import { Autocomplete } from '@mui/material'
import terms from 'assets/localize/terms'
import TextInput from 'components/textInput/TextInput'
import { findOccurrences } from 'services/string'
import './Autocomplete.scss'
import { useState } from 'react'

type Props = {
  objects: {value: string, label: string}[];
  value: string;
  onValueChange: (val: string) => void
  placeholder?: string;
  disabled?: boolean;
  clearable?: boolean;
}
function CustomAutocomplete({
  objects, value, onValueChange, placeholder = '', disabled = false, clearable = false,
}: Props) {
  const [searchValue, setSearchValue] = useState(null)
  return (
    <Autocomplete
      className="custom-autocomplete"
      disabled={disabled}
      options={objects}
      value={(objects.find(v => v.value === value)) ?? searchValue}
      getOptionLabel={option => (typeof option === 'string' ? option : option.label)}
      clearOnBlur
      freeSolo
      inputValue={searchValue ?? objects.find(v => v.value === value)?.label ?? ''}
      onChange={(_, v) => {
        if (typeof v === 'string' || v === null) {
          onValueChange(v)
        } else {
          onValueChange(v.value)
          setSearchValue('')
        }
      }}
      renderInput={params => (
        <TextInput
          {...params}
          placeholder={placeholder}
          value={searchValue ?? value ?? ''}
          onBlur={() => setSearchValue(null)}
          onChange={v => {
            if (objects.find(obj => obj.value === v)) {
              onValueChange(v)
            }
            setSearchValue(v)
          }}
        />
      )}
      noOptionsText={terms.Common.noResults}
      clearText={terms.Common.clear}
      disableClearable={!clearable}
      forcePopupIcon={false}
      renderOption={(props, option, { inputValue }) => {
        const label = typeof option === 'string' ? option : option.label
        const v = typeof option === 'string' ? option : option.value
        const matches = findOccurrences(label, inputValue)

        return (
          <li {...props} key={`${v}-${inputValue}`}>
            <div>
              {matches.map(part => (
                <span
                  key={crypto.randomUUID()}
                  style={{
                    fontWeight: part.match ? 700 : 400,
                  }}
                >
                  {part.segment}
                </span>
              ))}
            </div>
          </li>
        )
      }}
    />
  )
}

export default CustomAutocomplete
