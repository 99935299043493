import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MutableRefObject } from 'react'
import { Simulation } from 'reducers/types'
import { Dashboard } from 'types/trainer'

export type TrainerState = {
  dashboard: Dashboard;
  simulation: Simulation;
  trainerWebSocket: MutableRefObject<WebSocket>;
  paused: boolean;
}

const initialState: TrainerState = {
  dashboard: undefined,
  simulation: undefined,
  trainerWebSocket: undefined,
  paused: undefined,
}

const trainerSlice = createSlice({
  name: 'trainer',
  initialState,
  reducers: {
    setDashboard: (state, action: PayloadAction<Dashboard>) => {
      state.dashboard = action.payload
    },
    setSimulation: (state, action: PayloadAction<Simulation>) => {
      state.simulation = action.payload
    },
    stopSimulation: (state, action: PayloadAction<string>) => {
      const newDashboard = {
        ...state.dashboard,
        simulations: state.dashboard.simulations.map(sim => (sim.id === action.payload
          ? { ...sim, viewerStatus: 'STOPPED' } : sim)),
      }
      state.dashboard = newDashboard
    },
    setWebSocket: (state, action: PayloadAction<MutableRefObject<WebSocket>>) => {
      state.trainerWebSocket = action.payload
    },
    closeWebSocket: state => {
      if (state.trainerWebSocket?.current) {
        state.trainerWebSocket?.current.close()
        state.trainerWebSocket = undefined
      }
    },
    setPause: (state, action: PayloadAction<boolean>) => {
      state.paused = action.payload
    },
  },
})

export const {
  setDashboard,
  setSimulation,
  stopSimulation,
  setWebSocket: setTrainerWebSocket,
  closeWebSocket: closeTrainerWebSocket,
  setPause: setTrainerPause,
} = trainerSlice.actions

export default trainerSlice.reducer
