/* eslint-disable jsx-a11y/no-autofocus */
import { PayloadAction } from '@reduxjs/toolkit'
import { ReactComponent as MuteIcon } from 'assets/icons/mute.svg'
import { ReactComponent as SoundIcon } from 'assets/icons/sound.svg'
import {
  AudioPlayer,
  PanelsManager,
  SimulationStream,
} from 'components'
import { modalSignal } from 'components/modals/wrapper'
import { useAppDispatch, useAppSelector } from 'hooks'
import {
  MouseEvent, useCallback, useEffect, useRef, useState,
} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { getNetworkObjects } from 'reducers/networks/thunks'
import { setSelectedSimulation } from 'reducers/simulations/slices'
import { getSimulation } from 'reducers/simulations/thunks'
import { getSnciHtml } from 'reducers/snci/thunks'
import { RootState } from 'reducers/store'
import { Simulation, UrlParams } from 'reducers/types'
import { isOldSimulation } from 'services/route'
import { BroadcastMessage } from 'types/interfaces'
import { SocketMessage, TrainerSocketMessage } from 'types/websocket'
import { isDevEnv } from 'utils'
import './Snci.scss'
import { useCloseManagerTab } from 'features/snci/services/hooks'
import { isAutoOpenInterfacesEnabled } from 'features'
import ErrorModal from 'components/modals/errorModal/ErrorModal'
import TimeTools from './timeTools/TimeTools'

const DEFAULT_MUTED = true

export default function Snci() {
  const dispatch = useAppDispatch()
  const urlParams = useParams<UrlParams>()
  const broadcastRef = useRef<BroadcastChannel>()
  const { selectedSimulation, webSocket } = useAppSelector((state: RootState) => state.simulations)
  const {
    stream, html, beep,
  } = useAppSelector((state: RootState) => state.snci)
  const { stopped } = useAppSelector((state: RootState) => state.student)
  const hiddenInput = useRef(null)
  const iframe = useRef(null)
  const [userHasInteracted, setInteraction] = useState(false)
  const [queryParams] = useSearchParams()
  const [isMuted, setIsMuted] = useState(DEFAULT_MUTED)
  const isHistory = queryParams.get('mode') === 'history'
  const isMutedRef = useRef(DEFAULT_MUTED)
  useCloseManagerTab()

  const confirmClose = useCallback((e: Event) => {
    e.preventDefault()
  }, [])

  const sendCloseMessage = useCallback(() => {
    broadcastRef?.current.postMessage({ type: BroadcastMessage.closeSnci })
    broadcastRef?.current.postMessage({ type: BroadcastMessage.updateSoundState, muted: DEFAULT_MUTED })
  }, [])

  const openInterface = (screen: string) => () => {
    const params = isHistory ? '/static' : ''

    window.open(
      `${window.location.origin}/${screen}/${selectedSimulation.id}${params}`,
      '',
      `width=${window.innerWidth},height=${window.screen.availHeight}, left=${window.screen.availWidth}`,
    )
  }

  useEffect(() => {
    if (stopped === TrainerSocketMessage.closeDashboard) {
      modalSignal.value = <ErrorModal text="Le TP a été clôturé par le formateur" closable />
    }
  }, [stopped])

  useEffect(() => {
    if (!stream) return
    const iframeInterface = document.getElementById('iframe-wrapper') as HTMLIFrameElement
    const iframeDocument = iframeInterface.contentDocument
    Object.entries(stream).forEach(([key, value]) => {
      const element = iframeDocument.getElementById(key)
      const properties = Object.keys(value)
      if (element) {
        properties.forEach(prop => {
          if (prop === 'innerHTML') {
            element.innerHTML = value[prop]
            return
          }
          element.setAttribute(prop, value[prop])
        })
      }
    })
  }, [stream])

  useEffect(() => {
    if (urlParams.id && !broadcastRef.current) {
      const bc = new BroadcastChannel(urlParams.id)

      bc.postMessage({ type: BroadcastMessage.openSnci })

      bc.onmessage = (event: MessageEvent) => {
        if (event.data.type === BroadcastMessage.openTco) {
          broadcastRef?.current.postMessage({ type: BroadcastMessage.updateSoundState, muted: isMutedRef.current })
        }
        if (event.data.type === BroadcastMessage.closeSnci) {
          window.close()
        }
        if (event.data.type === BroadcastMessage.updateName) {
          dispatch(
            setSelectedSimulation({
              ...selectedSimulation,
              id: urlParams.id,
              name: event.data.message,
            }),
          )
        }
      }

      broadcastRef.current = bc

      dispatch(getSimulation(urlParams.id)).then(res => {
        const sim = (res.payload as PayloadAction<Simulation>).payload
        dispatch(getNetworkObjects(sim.scenario.networkId))
      })
    }

    if (selectedSimulation?.id) {
      isAutoOpenInterfacesEnabled().then(enabled => {
        if (enabled) {
          openInterface('tco')()
        }
      })
    }

    if (isOldSimulation(urlParams)) {
      window.addEventListener('beforeunload', sendCloseMessage)
    } else {
      window.addEventListener('beforeunload', confirmClose)
      window.addEventListener('unload', sendCloseMessage)
    }

    return () => {
      window.removeEventListener('beforeunload', confirmClose)
      window.removeEventListener('unload', sendCloseMessage)
      window.removeEventListener('beforeunload', sendCloseMessage)
    }
  }, [selectedSimulation])

  useEffect(() => {
    if (isOldSimulation(urlParams)) return

    if (modalSignal.value) {
      window.addEventListener('beforeunload', sendCloseMessage)
      window.removeEventListener('beforeunload', confirmClose)
    } else {
      window.removeEventListener('beforeunload', sendCloseMessage)
      window.addEventListener('beforeunload', confirmClose)
    }
  }, [modalSignal.value])

  // fix to add custom fonts to iframe
  const loadCustomFonts = () => {
    const frame = iframe.current as HTMLIFrameElement

    const style = document.createElement('style')
    style.textContent = `
      .Programmation {
          scrollbar-color: #6E62E5 black; /* Couleur du thumb et du track */
          scrollbar-width: thin; 
      }
      `
    frame.contentDocument.head.appendChild(style)

    const newFont = new FontFace('UnifontExMono', 'url(/fonts/UnifontExMono.woff)')
    newFont
      .load()
      .then(font => {
        frame.contentDocument.fonts.add(font)
      })
      .catch(() => {
        //
      })
  }

  useEffect(() => {
    dispatch(getSnciHtml())
    const frame = iframe.current as HTMLIFrameElement

    const interactWithWindow = () => {
      setInteraction(true)
    }
    const hideElements = () => {
      if (!isDevEnv()) {
        const gearIcon = frame.contentDocument.getElementById('gearIcon')
        if (gearIcon) {
          gearIcon.style.display = 'none'
        }
      }
    }

    const loadHandler = () => {
      loadCustomFonts()
      hideElements()
      frame.contentWindow.addEventListener('focus', () => {
        setTimeout(() => {
          hiddenInput?.current?.focus()
        }, 0)
      })

      // events to trigger sound when user has interacted with the app
      frame.contentWindow.addEventListener(
        'pointerdown',
        () => {
          interactWithWindow()
        },
        { once: true },
      )

      hiddenInput.current.addEventListener(
        'keydown',
        () => {
          interactWithWindow()
        },
        { once: true },
      )

      window.addEventListener('pointerdown', interactWithWindow, { once: true })

      return () => {
        frame.contentDocument.removeEventListener('pointerdown', () => {
          hiddenInput?.current?.focus()
          interactWithWindow()
        })
      }
    }

    frame.addEventListener('load', loadHandler)
    return () => {
      frame.removeEventListener('load', loadHandler)
    }
  }, [])

  const handleKeyPress = (event: React.KeyboardEvent) => {
    webSocket?.current?.send(
      JSON.stringify({
        messageType: SocketMessage.keyPress,
        key: event.key,
      }),
    )
  }

  const toggleMute = () => {
    setIsMuted(prevMuted => {
      isMutedRef.current = !prevMuted
      broadcastRef?.current.postMessage({ type: BroadcastMessage.updateSoundState, muted: !prevMuted })
      return !prevMuted
    })
  }

  const focusInput = (evt: MouseEvent<HTMLDivElement>) => {
    // allow inputs to be focused
    if (
      ((evt.target as HTMLElement)?.tagName === 'INPUT' && (evt.target as HTMLInputElement)?.type === 'text')
      || (typeof (evt.target as HTMLInputElement)?.className === 'string'
        && (evt.target as HTMLInputElement)?.className.includes('MuiInputBase-root'))
    ) return

    hiddenInput?.current?.focus()
  }

  return (
    <div className="snci" onClick={focusInput}>
      <input autoFocus ref={hiddenInput} onKeyDown={handleKeyPress} className="hidden-input" />

      <div className="screen-wrapper">
        <div className="toolbar">

          <TimeTools />

          <div className="secondary-controls">
            {/* to remove */}
            <button
              className="sound"
              type="button"
              onClick={openInterface('tst')}
              style={{
                color: '#ffffff',
                width: 'auto',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              TST
            </button>

            <button type="button" className="sound" onClick={toggleMute}>
              {isMuted ? <MuteIcon /> : <SoundIcon />}
            </button>

          </div>
        </div>

        <div className="content-wrapper">
          <div className="iframe-container">
            <iframe ref={iframe} title="snci" srcDoc={html} id="iframe-wrapper" />
          </div>
          <div className="panel-container">
            <PanelsManager />
          </div>
        </div>
      </div>

      <AudioPlayer beep={beep} canPlay={userHasInteracted} mute={isMuted} />

      <SimulationStream type="snci" />
      <SimulationStream type="train-manager" />
      <SimulationStream type="installation-manager" />
      <SimulationStream type="ancillary-command-manager" />
    </div>
  )
}
