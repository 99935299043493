import { Light } from 'types/snci'
import './AltLight.scss'

type Props = {
  light: Light;
}
export default function AltLight({ light }: Props) {
  return (
    <div className="alt-light">
      <h4>{light.name}</h4>

      <div className={`circle${light.state === 'ON' ? ' on' : ''}`} />
    </div>
  )
}
