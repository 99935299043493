import { ToggleSwitch } from 'components'
import { INSTALLATIONS_TOGGLE_OPTIONS } from 'features/snci/services/installations'
import { Toggle } from 'types/snci'
import './CustomToggle.scss'
import { useAppSelector } from 'utils'
import { InstallationSocketMessage } from 'types/websocket'

type Props = {
  toggle: Toggle;
}
export default function CustomToggle({ toggle }: Props) {
  const { installationWebSocket } = useAppSelector(state => state.snci)

  // refacto to generic
  const onChange = (state: string) => {
    if (state === toggle.currentState) return

    if (toggle.toggleType === 'RESTITUTION') {
      installationWebSocket?.current?.send(JSON.stringify({
        messageType: InstallationSocketMessage.toggleRestitution,
        state,
        automatonName: toggle.automatonName,
      }))
      return
    }

    if (toggle.toggleType === 'INTERDICTION') {
      installationWebSocket?.current?.send(JSON.stringify({
        messageType: InstallationSocketMessage.toggleInterdiction,
        state,
        automatonName: toggle.automatonName,
      }))
    }

    if (toggle.toggleType === 'HAND_MOTOR') {
      installationWebSocket?.current?.send(JSON.stringify({
        messageType: InstallationSocketMessage.toggleHandMotor,
        state,
        automatonName: toggle.automatonName,
      }))
    }

    if (toggle.toggleType === 'SWITCH_POSITION') {
      installationWebSocket?.current?.send(JSON.stringify({
        messageType: InstallationSocketMessage.toggleSwitch,
        state,
        automatonName: toggle.automatonName,
      }))
    }
  }

  return (
    <div className="authorization-toggle">
      <ToggleSwitch
        onChange={val => onChange(val)}
        options={INSTALLATIONS_TOGGLE_OPTIONS[toggle.toggleType]}
        value={toggle.currentState}
        title={toggle.verboseName}
        disabled={!toggle.visibility}
      />
    </div>
  )
}
