import { ReactComponent as ChevronIcon } from 'assets/icons/triangle-arrow-left.svg'
import terms from 'assets/localize/terms'
import {
  ButtonPadding,
  ButtonStyle, Information, PanelSignal, SimpleButton,
} from 'components'
import { Fragment } from 'react/jsx-runtime'
import { InstallationSocketMessage, SocketMessage } from 'types/websocket'
import { useAppDispatch, useAppSelector } from 'utils'
import { useEffect } from 'react'
import { setInstallationDetails } from 'reducers/snci/slices'
import Authorizations from '../AuthorizationsList'
import './AuthorizationDetails.scss'
import CustomToggle from './customToggle/CustomToggle'
import Light from './light/Light'
import Lock from './lock/Lock'
import AltLight from './altLight/AltLight'

export default function AuthorizationDetails() {
  const dispatch = useAppDispatch()
  const { installationWebSocket, installationDetails } = useAppSelector(state => state.snci)

  useEffect(() => () => {
    dispatch(setInstallationDetails(undefined))
  }, [])

  const goBack = () => {
    PanelSignal.value = <Authorizations />
    installationWebSocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.installationManager,
      consumerSpecific: true,
      state: SocketMessage.empty,
    }))
  }

  const enableAuthorization = (automatonName: string) => {
    installationWebSocket?.current?.send(JSON.stringify({
      messageType: InstallationSocketMessage.useActivationButton,
      automatonName,
    }))
  }

  return (
    <div className="authorization-details">

      <div className="title">

        <div className="go-back">
          <button type="button" onClick={goBack}>
            <ChevronIcon width={24} height={24} />
          </button>
          <h3 className="mode">
            {installationDetails?.name || ''}
          </h3>
        </div>
      </div>

      <div className="content">
        <Information type="info" message={terms.Snci.Panel.featureWarning} />
        {installationDetails?.descriptionItems.map(item => (
          <Fragment key={crypto.randomUUID()}>
            {item.itemType === 'RIBBED_LIGHT' && (
            <Light
              light={item}
              name={installationDetails?.name || ''}
            />
            )}

            {item.itemType === 'TOGGLE' && (
            <CustomToggle toggle={item} />
            )}

            {item.itemType === 'LOCK_CANCELLER_BUTTON' && (
            <Lock lock={item} />
            )}

            {item.itemType === 'INFORMATION_MESSAGE' && (
            <Information type="warning" message={item.message} />
            )}

            {item.itemType === 'LIGHT' && (
            <AltLight light={item} />
            )}

            {item.itemType === 'ACTIVATION_BUTTON' && (
            <SimpleButton
              disabled={!item.visibility}
              style={ButtonStyle.purple}
              padding={ButtonPadding.small}
              text={terms.Common.enable}
              onClick={() => enableAuthorization(item.automatonName)}
            />
            )}
          </Fragment>
        ))}

      </div>

    </div>

  )
}
