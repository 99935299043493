import './ToggleSwitch.scss'

type Props = {
  title: string;
  options: {value: string, label: string}[];
  value: string;
  onChange: (val: string) => void;
  disabled?: boolean;
}

export default function ToggleSwitch({
  title, options, value, onChange, disabled = false,
}: Props) {
  const getClassName = (option: {value: string, label: string}) => {
    if (value === option.value) {
      return ' active'
    }
    if (value.includes(option.value) && value.includes('BLINKING')) {
      return ' active blink'
    }

    return ''
  }

  return (
    <div className="custom-toggle">
      <span>{title}</span>
      <div className="switch">
        {options.map(opt => (
          <button
            disabled={disabled}
            key={opt.value}
            type="button"
            onClick={() => onChange(opt.value)}
            className={`option${getClassName(opt)}`}
          >
            {opt.label}
          </button>
        ))}

      </div>
    </div>
  )
}
