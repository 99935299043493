import { PanelSignal } from 'components'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShouldCloseManagerTab } from 'reducers/snci/slices'
import { RootState } from 'reducers/store'

export function useCloseManagerTab() {
  const dispatch = useDispatch()
  const { shouldCloseManagerTab } = useSelector((state: RootState) => state.snci)

  useEffect(() => {
    if (shouldCloseManagerTab) {
      dispatch(setShouldCloseManagerTab(false))
      PanelSignal.value = undefined
    }
  }, [shouldCloseManagerTab])
}
