import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  resetFeedbacksCheckboxes,
  setSelectedVersion,
  toggleDisplayComments, updateHistoryCommentsCheckboxes,
} from 'reducers/simulations/slices'
import {
  getCommentSeverityList,
  getSimulation,
  patchFeedback,
  postFeedback,
} from 'reducers/simulations/thunks'
import { Comment, Feedback } from 'reducers/types'
import './style.scss'
import SimpleButton, { ButtonStyle } from 'components/button/SimpleButton'
import { CustomRoutes } from 'services/route'
import HistoryTable from '../historyTable/HistoryTable'
import CommentForm from '../commentForm/CommentForm'

export default function Comments() {
  const dispatch = useAppDispatch()
  const {
    displayComments, feedbacksCheckboxes, selectedVersion, selectedSimulation,
    feedback,
  } = useAppSelector(state => state.simulations)
  const [newMessage, setNewMessage] = useState<string>('')
  const [selected, setSelected] = useState<Comment>(null)
  const [severity, setSeverity] = useState('')
  const [status, setStatus] = useState('')
  const [queryParams] = useSearchParams()
  const navigate = useNavigate()

  const resetForm = () => {
    setSelected(null)
    setNewMessage('')
    setSeverity('')
    setStatus('')
  }

  const handlePostFeedbacks = () => {
    if (!selected) {
      const params = {
        content: newMessage,
        logs: feedbacksCheckboxes,
        severity,
        status,
      }
      dispatch(postFeedback(selectedSimulation.id, params as unknown as Feedback)).then(res => {
        if (res.type.includes('fulfilled')) {
          resetForm()
        }
      })
    } else {
      const data = {
        id: selected.id,
        content: selected.content,
        logs: feedbacksCheckboxes,
        severity,
        status,
      }
      dispatch(patchFeedback(
        selectedSimulation.id,
        selected.id,
        data as unknown as Feedback,
      )).then(res => {
        if (res.type.includes('fulfilled')) {
          resetForm()
        }
      })
    }
  }

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, comment: Comment) => {
    if (e.target.checked) {
      setSelected(prev => (comment === prev ? null : comment))
      dispatch(updateHistoryCommentsCheckboxes(comment?.logs))
      setSeverity(comment.severity)
      setStatus(comment.status)
      setNewMessage(comment.content)
    } else {
      dispatch(resetFeedbacksCheckboxes())
      resetForm()
    }
  }

  useEffect(() => {
    if (selectedSimulation) {
      if (!selectedVersion) {
        dispatch(setSelectedVersion(selectedSimulation.scenario.versionId))
        dispatch(getSimulation(selectedSimulation.id))
        return
      }
      dispatch(getSimulation(selectedSimulation.id))
    }
  }, [feedback])

  useEffect(() => {
    dispatch(resetFeedbacksCheckboxes())
    dispatch(getCommentSeverityList())
  }, [])

  const handleBack = () => {
    if (queryParams.get('mode') === 'history') {
      window.close()
    } else {
      navigate(`${CustomRoutes.experimenter}/${selectedSimulation.id}`)
      dispatch(toggleDisplayComments(false))
    }
  }

  const handleChangeMessage = (e: string) => {
    if (!selected) {
      setNewMessage(e)
    } else {
      setSelected({
        ...selected,
        content: e,
      })
    }
  }

  return (
    <div className={`comments ${displayComments ? '' : 'd-none'}`}>
      <SimpleButton
        text={queryParams.get('mode') === 'history' ? 'Retourner à l‘historique' : 'Retourner à la simulation'}
        style={ButtonStyle.secondary}
        startIcon={<ArrowBackIcon />}
        onClick={() => handleBack()}
        sx={{
          width: '100%',
        }}
      />
      <Card variant="outlined" sx={{ flex: '1 1' }}>
        <CardContent sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          height: '100%',
          paddingRight: 0,
        }}
        >
          <strong>
            Historique des commentaires
          </strong>
          {selectedSimulation?.comments.length > 0 ? (
            <HistoryTable comments={selectedSimulation.comments} selected={selected} handleCheck={handleCheck} />
          ) : <span>Aucun commentaire à afficher</span> }
        </CardContent>
      </Card>
      <CommentForm
        selected={selected}
        handleChangeMessage={handleChangeMessage}
        setStatus={setStatus}
        status={status}
        setSeverity={setSeverity}
        severity={severity}
        newMessage={newMessage}
        handlePostFeedbacks={handlePostFeedbacks}
      />
    </div>
  )
}
