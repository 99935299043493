import ErrorIcon from '@mui/icons-material/Error'
import terms from 'assets/localize/terms'
import { ReactElement } from 'react'
import './style.scss'
import SimpleButton from 'components/button/SimpleButton'
import { modalSignal } from '../wrapper'

type Props = {
  text: string;
  closable?: boolean;
}
export default function ErrorModal({ text, closable }: Props): ReactElement {
  const closeModal = () => {
    modalSignal.value = undefined
  }
  return (
    <div className="error-popup">
      <div className="icon">
        <ErrorIcon />
      </div>
      <div className="content-wrapper">

        <div className="header">{text}</div>

      </div>

      {closable
          && (
          <div className="leave-buttons">

            <SimpleButton
              onClick={closeModal}
              text={terms.Common.continue}
            />

          </div>
          )}

    </div>
  )
}
