import { ReactComponent as PersonIcon } from 'assets/icons/person.svg'
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg'
import { ReactComponent as ChevronIcon } from 'assets/icons/triangle-arrow-left.svg'
import terms from 'assets/localize/terms'
import { ButtonPadding, ButtonStyle, SimpleButton } from 'components'
import { PanelSignal } from 'components/panelsManager/PanelsManager'
import TrainList from 'features/snci/components/trainList/TrainList'
import {
  getTrainSubtitle, ManeuverButtonState, trainDetailsNullRepresentation,
} from 'features/snci/services'
import { useAppSelector } from 'hooks'
import { ReactElement, useEffect } from 'react'
import { SocketMessage, TrainManagerSocketMessage } from 'types/websocket'
import DriverCommunication from './driverCommunication/DriverCommunication'
import ManeuverMode from './maneuverMode/ManeuverMode'
import MissionChange from './missionChange/MissionChange'
import './TrainDetails.scss'

type Props = {
  id: string;
}

export default function TrainDetails({ id }: Props): ReactElement {
  const { trainWebsocket, trainDetails } = useAppSelector(state => state.snci)

  const showCommunicationMode = () => {
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainManager,
      consumerSpecific: true,
      state: TrainManagerSocketMessage.driverCommunication,
      trainId: id,
    }))
    PanelSignal.value = <DriverCommunication id={id} />
  }

  const showManeuverMode = () => {
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainManager,
      consumerSpecific: true,
      state: TrainManagerSocketMessage.maneuverMode,
      trainId: id,
    }))
    PanelSignal.value = <ManeuverMode id={id} />
  }

  const showMissionChange = () => {
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainManager,
      consumerSpecific: true,
      state: TrainManagerSocketMessage.missionChange,
      trainId: id,
    }))
    PanelSignal.value = <MissionChange id={id} />
  }

  const changePanel = () => {
    PanelSignal.value = <TrainList />
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainManager,
      consumerSpecific: true,
      state: TrainManagerSocketMessage.trainList,
    }))
  }

  useEffect(() => {
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainManager,
      consumerSpecific: true,
      state: TrainManagerSocketMessage.trainDescription,
      trainId: id,
    }))
  }, [])

  const twinTrain = (message: string) => {
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: message,
      trainId: id,
    }))

    PanelSignal.value = <TrainList />
  }

  const getName = () => {
    if (trainDetails.twin) {
      return terms.Snci.Panel.twinnedTrain
    }

    if (trainDetails.untwin) {
      return terms.Snci.Panel.untwinnedTrain
    }

    return `${terms.Snci.Panel.train}${trainDetails.trainIndex}`
  }

  return (
    <div className="train-details">

      <div className="title">

        <div className="go-back">
          <button type="button" onClick={changePanel}>
            <ChevronIcon width={24} height={24} />
          </button>
          <h3 className="mode">
            {getName()}
          </h3>
        </div>

        <h5 className="index">
          {getTrainSubtitle(trainDetails)}
        </h5>
      </div>

      <div className="info-wrapper">
        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.rollingStock}</div>
          <div className="value">{trainDetails.rollingStock || trainDetailsNullRepresentation}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.composition}</div>
          <div className="value">{trainDetails.composition || trainDetailsNullRepresentation}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.traction}</div>
          <div className="value">{trainDetails.traction || trainDetailsNullRepresentation}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.length}</div>
          <div className="value">{trainDetails.length || trainDetailsNullRepresentation}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.destination}</div>
          <div className="value">{trainDetails.destination || trainDetailsNullRepresentation}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.direction}</div>
          <div className="value">{trainDetails.direction || trainDetailsNullRepresentation}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.speed}</div>
          <div className="value">{trainDetails.speed ?? trainDetailsNullRepresentation}</div>
        </div>
        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.pk}</div>
          <div className="value">{(trainDetails.pk || trainDetailsNullRepresentation).replace('_', ',')}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.observation}</div>
          <div className="value">{trainDetails.observation || trainDetailsNullRepresentation}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.compositionDetail}</div>
          <div className="value">{trainDetails.compositionDetail || trainDetailsNullRepresentation}</div>
        </div>

      </div>

      <div className="train-board">
        <div>
          <button
            type="button"
            className="actions"
            disabled={!trainDetails?.driverCommunicationVisibility}
            onClick={showCommunicationMode}
          >
            <PersonIcon width={24} height={24} />
            {terms.Snci.Panel.driverCommunication}

          </button>
          <button
            disabled={!trainDetails.missionChangeVisibility}
            type="button"
            className="actions"
            onClick={showMissionChange}
          >
            <SortIcon width={24} height={24} />
            {terms.Snci.Panel.missionChange}

          </button>
        </div>

        <div className="maneuver-pairing">

          <div>
            <SimpleButton
              disabled={!trainDetails?.twinningVisibility}
              style={ButtonStyle.lightPurple}
              padding={ButtonPadding.small}
              text={terms.Snci.Panel.TrainDetails.twinTrain}
              onClick={() => twinTrain(TrainManagerSocketMessage.twinTrain)}
            />

            <SimpleButton
              disabled={!trainDetails?.untwinningVisibility}
              padding={ButtonPadding.small}
              style={ButtonStyle.lightPurple}
              text={terms.Snci.Panel.TrainDetails.untwinTrain}
              onClick={() => twinTrain(TrainManagerSocketMessage.untrwinTrain)}
            />
          </div>
          {/* todo generic */}
          <button
            disabled={trainDetails?.maneuverButtonState === ManeuverButtonState.unavailable}
            type="button"
            className="actions centered"
            onClick={showManeuverMode}
          >
            {trainDetails?.maneuverButtonState === ManeuverButtonState.continue
              ? terms.Snci.Panel.backToManeuverMode : terms.Snci.Panel.maneuverMode}
          </button>

        </div>

      </div>

    </div>
  )
}
