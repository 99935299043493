import { SimulationStream } from 'components'
import {
  ReactElement,
  useEffect,
} from 'react'
import { useParams } from 'react-router-dom'
import { RootState } from 'reducers/store'
import { TrainerState } from 'reducers/trainer/slices'
import { getPracticalDashboard } from 'reducers/trainer/thunks'
import { useAppDispatch, useAppSelector } from 'utils'
import './Trainer.scss'

import RoomTopBar from 'features/trainer/components/roomTopBar/RoomTopBar'

export default function Trainer(): ReactElement {
  const dispatch = useAppDispatch()
  const { dashboard } = useAppSelector((state:RootState) => state.trainer as TrainerState)
  const params = useParams()

  useEffect(() => {
    if (params.id) {
      dispatch(getPracticalDashboard(params.id))
    }
  }, [])

  return (
    <div className="trainer-wrapper">
      <RoomTopBar dashboard={dashboard} />

      <SimulationStream type="dashboard" />
    </div>
  )
}
