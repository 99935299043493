import { TextField, TextFieldProps } from '@mui/material'
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg'
import {
  ReactElement,
  useEffect, useState,
} from 'react'
import './TextInput.scss'

type Props = {
  value: string;
  onChange: (val: string) => void
  error?: boolean;
  errorMessage?: string;
} & Omit<TextFieldProps, 'className' | 'onChange'>
export default function TextInput({
  value, onChange, error = false, errorMessage = '', ...buttonProps
}: Props): ReactElement {
  const [input, setInput] = useState(value)

  useEffect(() => {
    setInput(value)
  }, [value])

  const handleChange = (val: string) => {
    onChange(val)
  }

  return (
    <div className="custom-input">
      <TextField
        {...buttonProps}
        className={error ? 'error' : undefined}
        value={input}
        onChange={evt => handleChange(evt.target.value)}
      />
      {!!error && !!errorMessage && (
      <div className="error-message">
        <ErrorIcon />
        <span>{errorMessage}</span>
      </div>
      )}
    </div>

  )
}
