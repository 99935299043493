import { ReactComponent as ChevronIcon } from 'assets/icons/triangle-arrow-left.svg'
import terms from 'assets/localize/terms'
import { ButtonStyle, PanelSignal, SimpleButton } from 'components'
import { ButtonPadding, ButtonRadius } from 'components/button/SimpleButton'
import { useEffect } from 'react'
import { CommandSocketMessage, SocketMessage } from 'types/websocket'
import { useAppSelector } from 'utils'
import AncillaryCommandList from '../AncillaryCommandList'
import './TransitCancellation.scss'

export default function TransitCancellation() {
  const { commandWebSocket, commandItems } = useAppSelector(state => state.snci)

  useEffect(() => {
    commandWebSocket?.current?.send(
      JSON.stringify({
        messageType: SocketMessage.commandManager,
        consumerSpecific: true,
        state: CommandSocketMessage.transitCancellation,
      }),
    )
  }, [])

  const sendCancellation = () => {
    commandWebSocket?.current?.send(JSON.stringify({
      messageType: CommandSocketMessage.useTransitCancellation,
    }))
  }

  const goBack = () => {
    PanelSignal.value = <AncillaryCommandList />
    commandWebSocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.commandManager,
      consumerSpecific: true,
      state: SocketMessage.empty,
    }))
  }

  return (
    <div className="transit-cancellation">
      <div className="title">

        <div className="go-back">
          <button type="button" onClick={goBack}>
            <ChevronIcon width={24} height={24} />
          </button>
          <h3 className="mode">
            {`${terms.Snci.Panel.AncillaryCommands.transitCancellation}`}
          </h3>
        </div>

      </div>
      {commandItems.map((item, i) => (
        item.itemType === 'TRANSIT_CANCELLATION_COUNTER' && (
          // eslint-disable-next-line react/no-array-index-key
          <div className="action" key={i}>
            <h4>{terms.Snci.Panel.AncillaryCommands.counter}</h4>
            <div className="counter">
              <div className="digit-container">
                {item.counter.split('').map((digit, index) => (
                // eslint-disable-next-line react/no-array-index-key
                  <div className="number" key={index}>
                    <span key={digit}>{digit}</span>
                  </div>
                ))}
              </div>
            </div>

            <SimpleButton
              style={ButtonStyle.purple}
              padding={ButtonPadding.xxs}
              radius={ButtonRadius.medium}
              className="submit"
              onClick={sendCancellation}
              text={terms.Snci.Panel.AncillaryCommands.katr}
            />
          </div>
        )
      ))}
    </div>
  )
}
