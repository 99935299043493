import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import terms from 'assets/localize/terms'
import SimpleButton from 'components/button/SimpleButton'
import { modalSignal } from 'components/modals/wrapper'
import { useAppSelector } from 'hooks'
import { RootState } from 'reducers/store'
import './CrashModal.scss'
import { useDispatch } from 'react-redux'
import { setCrashMessage } from 'reducers/simulations/slices'

export default function CrashModal() {
  const dispatch = useDispatch()
  const { crashReason } = useAppSelector((state: RootState) => state.simulations)

  const closeModal = () => {
    modalSignal.value = undefined
    dispatch(setCrashMessage(''))
  }

  return (
    <div className="confirm-popup">
      <div className="error">
        <WarningAmberIcon />
      </div>
      <div className="content-wrapper">

        <div className="header">{terms.Simulation.crash}</div>

        <div className="body">{crashReason}</div>
      </div>

      <div className="leave-buttons">

        <SimpleButton
          onClick={closeModal}
          text={terms.Common.continue}
        />

      </div>
    </div>
  )
}
