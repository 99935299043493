import './Information.scss'

type Props = {
type: 'warning' | 'info';
message: string;
}

export default function Information({ type, message }: Props) {
  return (
    <div className={`information-message ${type}`}>
      <div className="banner" />
      <div className="message">{message}</div>
    </div>

  )
}
