import { ReactComponent as ChevronIcon } from 'assets/icons/triangle-arrow-left.svg'
import terms from 'assets/localize/terms'
import {
  ButtonPadding, ButtonStyle, CustomAutocomplete, SimpleButton,
  ToggleSwitch,
} from 'components'
import { ButtonRadius } from 'components/button/SimpleButton'
import { PanelSignal } from 'components/panelsManager/PanelsManager'
import TextInput from 'components/textInput/TextInput'
import {
  getTrainName,
  hasIndexError,
  isCorrectIndex, trainDetailsNullRepresentation,
} from 'features/snci/services'
import { useAppSelector } from 'hooks'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NetworksState } from 'reducers/networks/slices'
import { setFormError } from 'reducers/snci/slices'
import { Direction } from 'types/snci'
import { SocketMessage, TrainManagerSocketMessage } from 'types/websocket'
import DriverCommunication from '../driverCommunication/DriverCommunication'
import TrainDetails from '../TrainDetails'
import './MissionChange.scss'

type Props = {
  id: string
}

export default function MissionChange({ id }: Props) {
  const dispatch = useDispatch()
  const { trainWebsocket, mission, formError } = useAppSelector(state => state.snci)
  const [index, setIndex] = useState('')
  const [destination, setDestination] = useState(mission?.destination || '')
  const [direction, setDirection] = useState(mission?.direction || '')
  const { objects: networkObjects } = useAppSelector(state => state.networks as NetworksState)
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const showCommunicationMode = () => {
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainManager,
      consumerSpecific: true,
      state: TrainManagerSocketMessage.driverCommunication,
      trainId: id,
    }))
    PanelSignal.value = <DriverCommunication id={id} />
  }

  useEffect(() => {
    if (mission) {
      setDestination(mission.destination)
      setDirection(mission.direction)

      if (hasSubmitted) {
        showCommunicationMode()
      }
    }
  }, [mission])

  useEffect(() => {
    setHasSubmitted(false)
  }, [formError])

  useEffect(() => () => {
    dispatch(setFormError(undefined))
  }, [])

  const goBack = () => {
    PanelSignal.value = <TrainDetails id={id} />
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainManager,
      consumerSpecific: true,
      state: TrainManagerSocketMessage.trainList,
    }))
  }

  const sendMissionChange = () => {
    setHasSubmitted(true)
    trainWebsocket?.current?.send(
      JSON.stringify({
        messageType: TrainManagerSocketMessage.newMission,
        newDirection: direction,
        newIndex: index || mission?.trainIndex,
        newDestination: destination,
        trainId: id,
        consumerSpecific: true,
      }),
    )
    if (mission?.destination === destination
      && mission?.direction === direction
      && (!index || index === mission?.trainIndex)) {
      showCommunicationMode()
    }
  }

  const getErrorMessage = () => {
    if (hasIndexError(formError)) {
      return terms.Snci.Panel.MissionChange.existingTrain
    }
    if (index.length && !isCorrectIndex(index)) {
      return terms.Snci.Panel.MissionChange.indexError
    }
    return ''
  }

  const updateIndex = (value: string) => {
    setIndex(value)
    dispatch(setFormError(undefined))
  }

  const isSubmissionAvailable = () => !!(!index.length || isCorrectIndex(index))
    && !hasIndexError(formError)
    && (!!mission?.trainIndex || !!index.length)

  return (
    <div className="mission-change">
      <div className="title">

        <div className="go-back">
          <button type="button" onClick={goBack}>
            <ChevronIcon width={24} height={24} />
          </button>
          <h3 className="mode">
            {`${terms.Snci.Panel.MissionChange.title}`}
          </h3>
        </div>

        <h5 className="index">
          {getTrainName(mission)}
        </h5>
      </div>

      <div className="warning">
        <div />
        <div className="message">{terms.Snci.Panel.featureWarning}</div>
      </div>

      <div className="info">

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.MissionChange.destination}</div>
          <div className="value">{mission?.destination || trainDetailsNullRepresentation}</div>
        </div>

      </div>

      <div className="info">

        <div className="action">

          <h4>{terms.Snci.Panel.MissionChange.index}</h4>
          <TextInput
            value={index}
            onChange={updateIndex}
            error={hasIndexError(formError) || (index.length && !isCorrectIndex(index))}
            errorMessage={getErrorMessage()}
            placeholder={terms.Snci.Panel.MissionChange.index}
          />
        </div>

        <div className="action">

          <h4>{terms.Snci.Panel.TrainDetails.destination}</h4>
          <CustomAutocomplete
            objects={networkObjects.destinations.map(obj => ({ value: obj, label: obj }))}
            value={destination}
            placeholder={terms.Snci.Panel.TrainDetails.destination}
            onValueChange={setDestination}
          />
        </div>

        <div className="action">
          <ToggleSwitch
            title={terms.Snci.Panel.TrainDetails.direction}
            options={[{ value: Direction.even, label: Direction.even }, { value: Direction.odd, label: Direction.odd }]}
            value={direction}
            onChange={setDirection}
          />
        </div>

        <SimpleButton
          disabled={!isSubmissionAvailable()}
          style={ButtonStyle.lightPurple}
          padding={ButtonPadding.xxs}
          radius={ButtonRadius.medium}
          className="submit"
          onClick={sendMissionChange}
          text={terms.Common.give}
        />
      </div>

    </div>
  )
}
