import { UrlParams } from 'reducers/types'

export enum CustomRoutes {
  trainer = '/formateur',
  learner = '/apprenant',
  experimenter = '/experimentateur'
}

export const isTcoWindow = () => window.location.href.includes('tco')

export const isTptWindow = () => window.location.href.includes('tpt')

export const isSnciWindow = () => window.location.href.includes('snci')

export const isTstWindow = () => window.location.href.includes('tst')

export const isLogsWindow = () => window.location.href.includes(CustomRoutes.experimenter)

export const isOldSimulation = (params: Partial<UrlParams>) => params.mode === 'static'

export const isDependantWindow = () => isSnciWindow() || isTptWindow() || isTcoWindow() || isTstWindow()

export const isTrainerWindow = () => window.location.href.includes(CustomRoutes.trainer)

export const isInterfaceWindow = () => isTcoWindow() || isTptWindow()
