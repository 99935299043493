/* eslint-disable max-len */
import Checkbox from '@mui/material/Checkbox'
import { useAppDispatch, useAppSelector } from 'hooks'
import moment from 'moment'
import {
  useEffect, useMemo,
  useRef, useState,
} from 'react'
import { updateFeedbacksCheckboxes } from 'reducers/simulations/slices'
import { CheckedItems } from 'types/miscs'

import { useSearchParams } from 'react-router-dom'
import { LogEntry } from 'types/logs'
import './style.scss'

function MessageSection({ checkedItems, searchQuery }:
  { checkedItems: CheckedItems, searchQuery: string }) {
  const dispatch = useAppDispatch()
  const {
    messages, displayComments, feedbacksCheckboxes, selectedSimulation,
  } = useAppSelector(state => state.simulations)

  const messageContainerRef = useRef<HTMLDivElement>()
  const [prevScrollTop, setPrevScrollTop] = useState<number>()
  const [isScrollingUp, setIsScrollingUp] = useState(false)
  const [isSticked, setIsSticked] = useState(true)
  const [queryParams] = useSearchParams()

  const handleCheckboxChange = (id: string) => {
    dispatch(updateFeedbacksCheckboxes(id))
  }

  const formatMessage = (message: LogEntry) => {
    const logLevels = {
      ERROR: { color: 'red' },
      INFO: { color: 'black' },
      DEBUG: { color: 'gray' },
      SYSTEM: { color: 'violet' },
    }

    const logLevelsTime = {
      ERROR: `${moment(message.timestamp).format('HH:mm:ss.SSS')}`,
      INFO: `${moment(message.timestamp).format('HH:mm:ss')}`,
      DEBUG: `${moment(message.timestamp).format('HH:mm:ss.SSS')}`,
      SYSTEM: `${moment(message.timestamp).format('HH:mm:ss.SSS')}`,
    }
    const textStyle = logLevels[message.logLevel]

    const formattedMessage = `${message.content}`
    const formattedTime = logLevelsTime[message.logLevel]
    const coloredMessage = (
      <span style={textStyle} className="message">
        <span>{formattedTime}</span>
        <span>{formattedMessage}</span>
        {(displayComments || queryParams.get('showComments')) && (
        <Checkbox
          value={message.id}
          sx={{ padding: 0, marginLeft: 'auto' }}
          checked={feedbacksCheckboxes.includes(message.id)}
          onChange={() => handleCheckboxChange(message.id)}
        />
        )}
      </span>
    )

    return coloredMessage
  }

  const handleScroll = () => {
    if (messageContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageContainerRef?.current || {}
      if (prevScrollTop !== null) {
        const scrollDelta = scrollTop - (prevScrollTop as number)
        if (scrollDelta < 0) {
          setIsScrollingUp(true)
        } else {
          if (Math.abs(scrollTop + clientHeight - scrollHeight) < 5) {
            setIsSticked(true)
          }
          setIsScrollingUp(false)
        }
      }
      setPrevScrollTop(scrollTop)
    }
  }

  const filteredMessages = useMemo(() => messages
    .flatMap(message => {
      const {
        infoChecked, debugChecked, errorChecked, systemChecked,
      } = checkedItems
      const shouldDisplay = (infoChecked && ['INFO'].includes(message.logLevel))
        || (debugChecked && ['DEBUG'].includes(message.logLevel))
        || (errorChecked && ['ERROR'].includes(message.logLevel))
        || (systemChecked && ['SYSTEM'].includes(message.logLevel))

      if (shouldDisplay) {
        if ((moment(message.timestamp).format('HH:mm:ss.SSS').toLowerCase().includes(searchQuery.toLowerCase())
      || message.content.toLowerCase().includes(searchQuery.toLowerCase()))) {
          return formatMessage(message)
        }
        return []
      }
      return []
    }), [checkedItems, searchQuery, displayComments, messages])

  const filteredFeedbacks = useMemo(() => selectedSimulation?.logs
    .flatMap(message => {
      const {
        infoChecked, debugChecked, errorChecked, systemChecked,
      } = checkedItems
      const shouldDisplay = (infoChecked && ['INFO'].includes(message.logLevel))
      || (debugChecked && ['DEBUG'].includes(message.logLevel))
      || (errorChecked && ['ERROR'].includes(message.logLevel))
      || (systemChecked && ['SYSTEM'].includes(message.logLevel))

      if (shouldDisplay) {
        if ((moment(message.timestamp).format('HH:mm:ss.SSS').toLowerCase().includes(searchQuery.toLowerCase())
    || message.content.toLowerCase().includes(searchQuery.toLowerCase()))) {
          return formatMessage(message)
        }
        return []
      }
      return []
    }), [checkedItems, searchQuery, selectedSimulation, displayComments,
    feedbacksCheckboxes])

  useEffect(() => {
    if (isScrollingUp && isSticked) {
      setIsSticked(false)
    }

    const messageContainer = messageContainerRef.current
    if (!messageContainer) return

    const { scrollTop, scrollHeight, clientHeight } = messageContainer || {}
    if (Math.abs(scrollTop + clientHeight - scrollHeight) < 5) {
      setIsSticked(true)
    }
    if (messageContainer && isSticked && !isScrollingUp) {
      messageContainer.scrollTop = messageContainer.scrollHeight
    }
  }, [messages])

  const createUniqueIdGenerator = () => {
    let counter = 0
    const generateUniqueId = () => {
      counter += 1
      return counter
    }
    return generateUniqueId
  }
  const generateUniqueId = createUniqueIdGenerator()

  return (
    <div className="messages-section">
      <div className="header">
        <div>Heure</div>
        <div>Logs</div>
      </div>
      <div
        ref={messageContainerRef}
        style={{ overflowY: 'auto' }}
        onScroll={handleScroll}
        className="messages"
      >
        {(displayComments ? (filteredFeedbacks || []) : filteredMessages).map(message => (
          <div key={generateUniqueId()}>{message}</div>
        ))}
      </div>
    </div>
  )
}

export default MessageSection
