export enum SocketMessage {
  geojson = 'GEOJSON_PATCH',
  time = 'TIME',
  svg = 'SVG_PATCH',
  pause = 'PAUSE',
  stop = 'STOP',
  start = 'START',
  interaction = 'INTERACTION',
  speed = 'SPEED',
  keyPress = 'KEY_PRESS',
  clearLogs = 'CLEAR_LOGS',
  forcePause = 'FORCE_PAUSE',
  finalRecordedTime = 'FINAL_RECORDED_TIME',
  longBeep = 'LONG_BEEP',
  shortBeep = 'SHORT_BEEP',
  click = 'CLICK',
  clickabilityForm = 'CLICKABILITY_FORM',
  trainManager = 'TRAIN_MANAGER_STATE',
  trainOnSight = 'ON_SIGHT',
  crash = 'CRASH',
  installationManager = 'INSTALLATION_MANAGER_STATE',
  empty = 'EMPTY',
  discordanceBeep = 'DISCORDANCE_BEEP',
  commandManager = 'ANCILLARY_COMMAND_MANAGER_STATE',
  transitCancellationBeep = 'TRANSIT_CANCELLATION_BEEP'
}

export enum TrainManagerSocketMessage {
  trainList = 'TRAIN_LIST',
  trainDescription = 'TRAIN_DESCRIPTION',
  trainStaticDescription = 'TRAIN_STATIC_DESCRIPTION',
  trainDynamicDescription = 'TRAIN_DYNAMIC_DESCRIPTION',
  empty = 'EMPTY',
  emergencyStop = 'EMERGENCY_STOP',
  stopAtPk = 'STOP_AT_PK',
  stopAtSignal = 'STOP_AT_SIGNAL',
  driverCommunication = 'TRAIN_DRIVER_COMMUNICATION',
  pkError = 'PK_ERROR',
  stopCancellation = 'STOP_CANCELLATION',
  movingWithPk = 'MOVING_AUTHORIZATION_WITH_PK',
  moving = 'MOVING_AUTHORIZATION',
  trainPk = 'TRAIN_PK',
  crossingAuthorization = 'CROSSING_AUTHORIZATION',
  resumeNormalOperation = 'RESUME_NORMAL_OPERATION',
  maneuverMode = 'TRAIN_MANEUVER',
  maneuverDescription = 'TRAIN_MANEUVER_DESCRIPTION',
  trainUturn = 'UTURN_TRAIN',
  trainMove = 'MOVE_TRAIN',
  trainStop = 'STOP_TRAIN',
  exitManeuverMode = 'EXIT_MANEUVER',
  missionChange = 'TRAIN_MISSION_CHANGE',
  missionDescription = 'TRAIN_MISSION_DESCRIPTION',
  newMission = 'MISSION_CHANGE',
  indexError = 'INDEX_ERROR',
  twinTrain = 'TWIN_TRAIN',
  untrwinTrain = 'UNTWIN_TRAIN',
  closeManeuverMode = 'CLOSE_MANEUVER_MODE',
  closeManagerTab = 'CLOSE_MANAGER_TAB',
}

export enum InstallationSocketMessage {
  installationDescription = 'INSTALLATION_DESCRIPTION',
  toggleRestitution = 'TOGGLE_RESTITUTION',
  toggleInterdiction = 'TOGGLE_INTERDICTION',
  useLockCanceller = 'USE_LOCK_CANCELLER',
  useActivationButton = 'USE_ACTIVATION_BUTTON',
  switchList = 'SWITCH_LIST',
  toggleHandMotor = 'TOGGLE_HAND_MOTOR',
  toggleSwitch = 'TOGGLE_SWITCH_POSITION',
  transitList= 'TRANSIT_CANCELLATION_LIST',
  useTransitCancellation = 'USE_TRANSIT_CANCELLATION_BUTTON',
  useActuationButton = 'USE_ACTUATION_BUTTON'
}

export enum CommandSocketMessage {
  transitCancellation = 'TRANSIT_CANCELLATION_COUNTER',
  commandDescription = 'ANCILLARY_COMMAND_DESCRIPTION',
  useTransitCancellation = 'USE_TRANSIT_CANCELLATION_BUTTON'
}

export enum TrainerSocketMessage {
  closeDashboard = 'CLOSE_DASHBOARD'
}

export enum Interaction {
  toggle = 'TOGGLE',
}

type FormAction = {
  disturbanceId: string
  disturbanceName: string
  disturbanceType: string
  available: boolean
}

export type InteractionForm = {
  automatonVerboseName: string
  automatonName: string
  actions: FormAction[]
}
