import { Dialog, TextField } from '@mui/material'
import { ReactElement, useState } from 'react'
import './Student.scss'
import { useAppDispatch } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { createSimulationFromDashboard } from 'reducers/student/thunks'
import SimpleButton from 'components/button/SimpleButton'
import { setSnackbar, SnackbarSeverity } from 'reducers/app'
import { Simulation } from 'reducers/types'
import terms from 'assets/localize/terms'

// todo everything
export default function Student(): ReactElement {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [code, setCode] = useState('')

  const joinRoom = () => {
    dispatch(createSimulationFromDashboard(code)).unwrap().then(res => {
      navigate(`/snci/${(res.payload as Simulation).id}`)
    })
      .catch(err => {
        if (err.response.status === 404) {
          dispatch(setSnackbar({ message: terms.Error.dashboard.invalidCode, severity: SnackbarSeverity.ERROR }))
        } else {
          dispatch(setSnackbar({ message: terms.Error.httpStatus.unknownError, severity: SnackbarSeverity.ERROR }))
        }
      })
  }

  return (
    <Dialog
      className="student-modal"
      open
    >
      <div className="div">
        {terms.Learner.enterCode}
        <TextField value={code} onChange={evt => setCode(evt.target.value)} />

        <SimpleButton text="Rejoindre" onClick={joinRoom} disabled={!code.trim().length || code.trim().length !== 6} />
      </div>

    </Dialog>
  )
}
