import { ManeuverButtonState } from 'features/snci'
import { TrainManagerSocketMessage } from './websocket'

export type Train = {
  index: string;
  id: string;
  twin: boolean;
  untwin: boolean;
  untwinDetail: string;
}

export enum SightState {
  onSight = 'ON_SIGHT',
  notOnSight = 'NOT_ON_SIGHT',
  transitionToSight = 'TRANSITION_TO_ON_SIGHT'
}

export enum Direction {
  even = 'Pair',
  odd = 'Impair'
}

export type TwinDetail = {
  twin: boolean;
  untwin: boolean;
  untwinDetail: string;
}

export type TrainDetails = TwinDetail & {
  trainIndex: string;
  onSightState: SightState;
  pk: string;
  trainId: number;
  speed: number;
  direction: string;
  rollingStock: string;
  observation: string;
  traction: string;
  destination: string;
  composition: string;
  compositionDetail: string;
  length: number;
  maneuverButtonState: ManeuverButtonState;
  missionChangeVisibility: boolean;
  twinningVisibility: boolean;
  untwinningVisibility: boolean;
  driverCommunicationVisibility: boolean;
}

export type DriverCommunication = TwinDetail & {
  crossingAuthorizationVisibility: boolean;
  currentConditionsVisibility: boolean;
  trainId: number;
  trainIndex: string;
  movingAuthorizationVisibility: boolean;
  stopPK: string | null;
  stopSignal: string | null;
  emergencyStopVisibility: boolean;
}

export type PKError = {messageType: TrainManagerSocketMessage.pkError, pk: string}

export type IndexError = {messageType: TrainManagerSocketMessage.indexError, index: string}

export type FormError = PKError | IndexError

export type Maneuver = TwinDetail & {
  crossingAuthorizationVisibility: boolean;
  direction: string;
  forwardButtonState: string;
  maneuverExitVisibility: boolean;
  state: string;
  trainId: number;
  trainIndex: string;
  uturnVisibility: boolean;
}

export type Mission = TwinDetail & {
  destination: string;
  direction: string;
  missionChangeVisibility: boolean;
  trainId: string;
  trainIndex: string;
}

export type RibbedLight = {
  itemType: 'RIBBED_LIGHT';
  name: string;
  state: 'RIBBED' | 'WHITE';
}

export type Toggle = {
  automatonName: string;
  currentState: string;
  itemType: 'TOGGLE';
  toggleType: 'INTERDICTION' | 'RESTITUTION' | 'HAND_MOTOR' | 'SWITCH_POSITION';
  verboseName: string;
  visibility: boolean;
}

export type LockButton = {
 automatonName: string;
 itemType: 'LOCK_CANCELLER_BUTTON';
 useState: boolean;
 verboseName: string;
 visibility: boolean;
}

export type InformationMessage = {
  itemType: 'INFORMATION_MESSAGE';
  message: string;
}

export type Light = {
  itemType: 'LIGHT';
  name: string;
  state: 'OFF' | 'ON';
}

export type ActivationButton = {
  automatonName: string;
  itemType: 'ACTIVATION_BUTTON';
  visibility: boolean;
}

export type SwitchList = {
  itemType: 'SWITCH_LIST'
}

export type TransitList = {
  itemType: 'TRANSIT_CANCELLATION_LIST'
}

export type ActuationButton = {
  itemType: 'ACTUATION_BUTTON';
  visibility: boolean;
  automatonName: string;
}

export type Installation = {
  name: string;
  descriptionItems: (RibbedLight | Toggle | LockButton | InformationMessage | Light | ActivationButton |
    SwitchList | TransitList | ActuationButton)[]
}

export type TransitCancellationCounter = {
  itemType: 'TRANSIT_CANCELLATION_COUNTER';
  counter: string;
}
