import { ReactComponent as PlayIcon } from 'assets/icons/play.svg'
import terms from 'assets/localize/terms'
import { PanelSignal } from 'components/panelsManager/PanelsManager'
import TrainDetails from 'features/snci/components/trainDetails/TrainDetails'
import { useAppSelector } from 'hooks'
import { ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetTrainDetails } from 'reducers/snci/slices'
import { Train } from 'types/snci'
import { SocketMessage, TrainManagerSocketMessage } from 'types/websocket'
import './TrainList.scss'

export default function TrainList(): ReactElement {
  const dispatch = useDispatch()
  const { trainWebsocket, trains } = useAppSelector(state => state.snci)

  useEffect(() => {
    dispatch(resetTrainDetails())
    trainWebsocket?.current?.send(
      JSON.stringify({
        messageType: SocketMessage.trainManager,
        consumerSpecific: true,
        state: TrainManagerSocketMessage.trainList,
      }),
    )
  }, [])

  const getTrainDetail = (id: string) => {
    PanelSignal.value = <TrainDetails id={id} />
  }

  const getTwinInfo = (train: Train) => {
    if (train.twin) {
      return terms.Snci.Panel.twinnedTrain
    }

    return train.untwin ? (
      <>
        {terms.Snci.Panel.untwinnedTrain}
        <span className="untwin-detail">
          (
          {train.untwinDetail}
          )
        </span>
      </>

    ) : `${terms.Snci.Panel.train}${train.index}`
  }

  return (
    <>
      <h3 className="panel-title">{terms.Snci.Panel.trainList}</h3>

      <div className="train-panel">
        {trains.length
          ? trains.map(train => (
            <button
              key={train.id}
              type="button"
              onClick={() => getTrainDetail(train.id)}
              className={train.twin || train.untwin ? 'twin' : undefined}
            >
              <div className="info">
                <div className="index">
                  {getTwinInfo(train)}
                </div>
                <PlayIcon width={24} height={24} />
              </div>
            </button>
          ))
          : terms.Snci.Panel.noTrains}
      </div>
    </>
  )
}
