import terms from 'assets/localize/terms'
import { ButtonPadding, ButtonStyle, SimpleButton } from 'components'
import { useAppSelector } from 'hooks'
import { TrainManagerSocketMessage } from 'types/websocket'
import './MovingConditions.scss'

type Props = {
  id: string;
}

export default function MovingConditions({ id }: Props) {
  const { trainWebsocket } = useAppSelector(state => state.snci)

  const sendNormalRun = () => {
    trainWebsocket?.current?.send(
      JSON.stringify({
        messageType: TrainManagerSocketMessage.resumeNormalOperation,
        trainId: id,
      }),
    )
  }

  return (
    <div className="moving-conditions">
      <div className="warning">
        <div />
        <div className="message">{terms.Snci.Panel.Communication.disabledWhenMoving}</div>

      </div>

      <div className="current-conditions">
        <h4>{terms.Snci.Panel.Communication.conditionsRunning}</h4>

        <div>
          {terms.Snci.Panel.Communication.conditionsInfo}
        </div>

        <SimpleButton
          className="submit"
          padding={ButtonPadding.small}
          style={ButtonStyle.lightPurple}
          text={terms.Snci.Panel.Communication.startNormalRun}
          onClick={sendNormalRun}
        />

      </div>
    </div>
  )
}
