import { ReactComponent as FastForward } from 'assets/icons/fast-forward.svg'
import { ReactComponent as PlayPauseIcon } from 'assets/icons/play-pause.svg'
import { ReactComponent as Pause } from 'assets/icons/pause.svg'
import terms from 'assets/localize/terms'
import { ButtonPadding, ButtonStyle, SimpleButton } from 'components'
import { speedSignal } from 'features/logs'
import { useAppSelector } from 'hooks'
import { RootState } from 'reducers/store'
import './TimeTools.scss'
import { SocketMessage } from 'types/websocket'

export default function TimeTools() {
  const {
    webSocket, paused, startButtonEnabled,
  } = useAppSelector((state: RootState) => state.simulations)

  const getIcon = () => {
    if (!webSocket || typeof paused !== 'boolean') {
      return <PlayPauseIcon width={24} height={24} />
    }

    if (paused) {
      return <FastForward />
    }

    return <Pause />
  }

  const changeSimulationState = () => {
    webSocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.pause,
      pause: !paused,
    }))
  }

  const changeSpeed = (speed: number) => {
    webSocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.speed,
      speed,
    }))
  }

  const isSimulationConnected = () => !webSocket

  return (

    <div className="controls">
      <SimpleButton
        padding={ButtonPadding.xxs}
        style={ButtonStyle.purple}
        text={(!webSocket) || paused ? terms.Common.play : terms.Common.pause}
        startIcon={getIcon()}
        disabled={isSimulationConnected() || !startButtonEnabled}
        onClick={changeSimulationState}
      />

      <div className="speed">
        <SimpleButton
          className={speedSignal.value === 0.5 ? 'active' : ''}
          padding={ButtonPadding.xxs}
          style={ButtonStyle.purple}
          text="x0,5"
          disabled={isSimulationConnected() || !startButtonEnabled}
          onClick={() => changeSpeed(0.5)}
        />
        <SimpleButton
          className={speedSignal.value === 1 ? 'active' : ''}
          padding={ButtonPadding.xxs}
          style={ButtonStyle.purple}
          text="x1"
          disabled={isSimulationConnected() || !startButtonEnabled}
          onClick={() => changeSpeed(1)}
        />
        <SimpleButton
          className={speedSignal.value === 2 ? 'active' : ''}
          padding={ButtonPadding.xxs}
          style={ButtonStyle.purple}
          text="x2"
          disabled={isSimulationConnected() || !startButtonEnabled}
          onClick={() => changeSpeed(2)}
        />
        <SimpleButton
          className={speedSignal.value === 3 ? 'active' : ''}
          padding={ButtonPadding.xxs}
          style={ButtonStyle.purple}
          text="x3"
          disabled={isSimulationConnected() || !startButtonEnabled}
          onClick={() => changeSpeed(3)}
        />
        <SimpleButton
          className={speedSignal.value >= 4 ? 'active' : ''}
          padding={ButtonPadding.xxs}
          style={ButtonStyle.purple}
          text="x4"
          disabled={isSimulationConnected() || !startButtonEnabled}
          onClick={() => changeSpeed(4)}
        />
      </div>

    </div>

  )
}
