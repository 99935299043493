import { ReactComponent as ChevronIcon } from 'assets/icons/triangle-arrow-left.svg'
import terms from 'assets/localize/terms'
import { CustomAutocomplete, Information, PanelSignal } from 'components'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NetworksState } from 'reducers/networks/slices'
import { setInstallationDetails } from 'reducers/snci/slices'
import { InstallationSocketMessage, SocketMessage } from 'types/websocket'
import { useAppSelector } from 'utils'
import CustomToggle from '../installationList/authorizations/authorizationDetails/customToggle/CustomToggle'
import InstallationList from '../installationList/InstallationList'
import './Switches.scss'

export default function Switches() {
  const dispatch = useDispatch()
  const [selectedSwitch, setSwitch] = useState(null)
  const { objects: networkObjects } = useAppSelector(state => state.networks as NetworksState)
  const { installationWebSocket, installationDetails } = useAppSelector(state => state.snci)

  useEffect(() => () => {
    dispatch(setInstallationDetails(undefined))
  }, [])

  const goBack = () => {
    PanelSignal.value = <InstallationList />
  }

  const selectSwitch = (automatonName: string) => {
    setSwitch(automatonName)
    installationWebSocket?.current?.send(
      JSON.stringify({
        messageType: SocketMessage.installationManager,
        state: InstallationSocketMessage.installationDescription,
        automatonName,
        consumerSpecific: true,
      }),
    )
  }

  return (
    <div className="switches-list">
      <div className="title">
        <div className="go-back">
          <button type="button" onClick={goBack}>
            <ChevronIcon width={24} height={24} />
          </button>
          <h3 className="mode">
            {terms.Snci.Panel.switches}
          </h3>
        </div>
      </div>

      <Information type="info" message={terms.Snci.Panel.featureWarning} />

      {installationDetails?.descriptionItems?.map((item, index) => (
        <Fragment key={`${item.itemType}-${'verboseName' in item ? item.verboseName : index}`}>

          {item.itemType === 'SWITCH_LIST' && (
          <div className="action">
            <h4 className="label">{terms.Common.searchObject}</h4>

            <CustomAutocomplete
              objects={networkObjects.switches.map(obj => ({ value: obj.automatonName, label: obj.verboseName }))}
              value={selectedSwitch}
              placeholder={terms.Snci.Panel.Installations.switchName}
              onValueChange={selectSwitch}
            />

          </div>
          )}

          {item.itemType === 'TOGGLE' && (
          <CustomToggle toggle={item} />
          )}

        </Fragment>
      ))}

    </div>
  )
}
