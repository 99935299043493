import { ReactComponent as PlayIcon } from 'assets/icons/play.svg'
import terms from 'assets/localize/terms'
import { PanelSignal } from 'components'
import { InstallationSocketMessage, SocketMessage } from 'types/websocket'
import { useAppSelector } from 'utils'
import Switches from '../switches/Switches'
import AuthorizationsList from './authorizations/AuthorizationsList'
import './InstallationList.scss'
import TransitCancellations from '../transitCancellations/TransitCancellations'

export default function InstallationList() {
  const { installationWebSocket } = useAppSelector(state => state.snci)

  const selectInstallation = (installation: string) => {
    if (installation === 'authorizations') {
      PanelSignal.value = <AuthorizationsList />
    }

    if (installation === 'switches') {
      installationWebSocket?.current?.send(
        JSON.stringify({
          messageType: SocketMessage.installationManager,
          state: InstallationSocketMessage.switchList,
          consumerSpecific: true,
        }),
      )
      PanelSignal.value = <Switches />
    }

    if (installation === 'transitCancellations') {
      installationWebSocket?.current?.send(
        JSON.stringify({
          messageType: SocketMessage.installationManager,
          state: InstallationSocketMessage.transitList,
          consumerSpecific: true,
        }),
      )
      PanelSignal.value = <TransitCancellations />
    }
  }

  return (
    <>
      <h3 className="panel-title">{terms.Snci.Panel.installations}</h3>

      <div className="panel-content">
        <button
          type="button"
          onClick={() => selectInstallation('switches')}
        >
          {terms.Snci.Panel.Installations.adv}
          <div className="info">
            <PlayIcon width={24} height={24} />
          </div>
        </button>

        <button
          type="button"
          onClick={() => selectInstallation('authorizations')}
        >
          {terms.Snci.Panel.Installations.authorization}
          <div className="info">
            <PlayIcon width={24} height={24} />
          </div>
        </button>

        <button
          type="button"
          onClick={() => selectInstallation('transitCancellations')}
        >
          {terms.Snci.Panel.Installations.transit}
          <div className="info">
            <PlayIcon width={24} height={24} />
          </div>
        </button>
      </div>
    </>
  )
}
