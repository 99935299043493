import { RibbedLight } from 'types/snci'
import './Light.scss'
import terms from 'assets/localize/terms'

type Props = {
  light: RibbedLight;
  name: string;
}
export default function Light({ light, name }: Props) {
  return (
    <div className="ribbed-light">
      <h4>{light.name || terms.Snci.Panel.Installations.light(name)}</h4>

      <div className={`circle${light.state === 'RIBBED' ? '' : ' white'}`} />
    </div>
  )
}
