import './style.scss'

type Props = {
  message?: string | undefined;
  variant?: 'normal' | 'small' | 'large'
  standalone?: boolean
}

export default function Loader({ message = undefined, variant = 'normal', standalone = false }: Props) {
  return (
    <div className={`loader ${variant}${standalone ? ' standalone' : ''}`}>
      <div className="spinner" />
      {message && <div>{`${message}...`}</div>}
    </div>
  )
}
