import { Toggle } from 'types/snci'

type ToggleOptions = {
  [key in Toggle['toggleType']]: ({ value: string; label: string }[])
}
export const INSTALLATIONS_TOGGLE_OPTIONS: ToggleOptions = {
  RESTITUTION: [{ value: 'RETURNED', label: 'Restitution' }, { value: 'RELEASED', label: 'Prise' }],
  INTERDICTION: [{ value: 'FORBIDDEN', label: 'Interdit' }, { value: 'AUTHORIZED', label: 'Autorisé' }],
  HAND_MOTOR: [{ value: 'MOTOR', label: 'Moteur' }, { value: 'HAND', label: 'Main' }],
  SWITCH_POSITION: [{ value: 'LEFT', label: 'Gauche' }, { value: 'RIGHT', label: 'Droite' }],
}
