import { ReactElement } from 'react'
import Button from '@mui/material/Button'

import './style.scss'

export enum ButtonStyle {
  primary = 'primary',
  light = 'light',
  secondary = 'secondary',
  rounded = 'rounded',
  purple = 'purple',
  roundedPurple = 'rounded-purple',
  lightPurple = 'light-purple',
  borderlessLightPurple = 'borderless-light-purple',
  roundedWhite = 'rounded-white'
}

export enum ButtonPadding {
  xxs= 'xxs',
  xs = 'xs',
  small = 'small',
  medium = 'medium',
  large = 'large',
  xl = 'xl'
}

export enum ButtonRadius {
  small = 'r-sm',
  medium = 'r-m',
  large = 'r-l'
}

type Props = {
  className?: string;
  padding?: ButtonPadding;
  text: string;
  onClick?: () => void;
  style?: ButtonStyle;
  disabled?: boolean;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  radius?: ButtonRadius;
  sx?: object;
}
export default function SimpleButton({
  className = '', padding = ButtonPadding.small, text,
  onClick = () => { /* to implement */ }, style = ButtonStyle.primary,
  disabled = false, startIcon = null, endIcon = null, sx = {}, radius = undefined,
}: Props): ReactElement {
  return (
    <Button
      // eslint-disable-next-line max-len
      className={`simple-button button--${style} ${padding}${radius ? ` ${radius}` : ''}${className ? ` ${className}` : ''}`}
      variant="contained"
      size="small"
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={sx}
    >
      {text}
    </Button>
  )
}
