import { ReactElement } from 'react'

import './style.scss'

export enum BoxStyle {
  selected = 'selected',
  unselected = 'unselected',
}

type Props = {
  text: string;
  onClick?: () => void;
  style?: BoxStyle;
  icon?: JSX.Element;
}

export default function SelectBox({
  text, onClick = () => { /* to implement */ }, style = BoxStyle.unselected, icon = null,
}: Props): ReactElement {
  return (
    <div
      className={`select-box box--${style}`}
      onClick={onClick}
    >
      {icon}
      {text}
    </div>
  )
}
