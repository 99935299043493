import { modalSignal } from 'components/modals/wrapper'
import { useAppDispatch, useAppSelector } from 'hooks'
import {
  MouseEvent, useCallback, useEffect, useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { setSelectedSimulation } from 'reducers/simulations/slices'
import { getSimulation } from 'reducers/simulations/thunks'
import { RootState } from 'reducers/store'
import { UrlParams } from 'reducers/types'
import { BroadcastMessage } from 'types/interfaces'

import { AudioPlayer, SimulationStream, SvgWrapper } from 'components'
import { setForm } from 'reducers/interfaces/slices'
import { getTcoSvg, getTptSvg } from 'reducers/interfaces/thunks'
import { isOldSimulation, isTcoWindow, isTptWindow } from 'services/route'
import { SocketMessage } from 'types/websocket'
import { capitalize, isDevEnv } from 'utils'

export default function Interface() {
  const dispatch = useAppDispatch()
  const urlParams = useParams<UrlParams>()
  const broadcastRef = useRef<BroadcastChannel>()
  const { selectedSimulation, webSocket } = useAppSelector((state: RootState) => state.simulations)
  const { stream, svg } = useAppSelector((state: RootState) => state.interfaces)
  const [coords, setCoords] = useState(({ x: 0, y: 0 }))
  const {
    tcoBeep, altBeep,
  } = useAppSelector((state: RootState) => state.interfaces)
  // todo generic
  const [userHasInteracted, setInteraction] = useState(false)
  const [isMuted, setIsMuted] = useState(true)

  const confirmClose = useCallback((e: Event) => {
    e.preventDefault()
  }, [])

  const sendCloseMessage = useCallback(() => {
    const closeMessage = isTcoWindow() ? BroadcastMessage.closeTco : BroadcastMessage.closeTpt
    broadcastRef?.current.postMessage({ type: closeMessage })
  }, [])

  useEffect(() => {
    const state = urlParams.mode === 'static' ? 'on' : 'off'

    const interactWithWindow = () => {
      setInteraction(true)
    }

    window.addEventListener('pointerdown', interactWithWindow, { once: true })

    switch (true) {
      case isTcoWindow():
        dispatch(getTcoSvg(state))
        break

      case isTptWindow():
        dispatch(getTptSvg())
        break

      default:
          //
    }

    return () => {
      window.removeEventListener('pointerdown', interactWithWindow)
    }
  }, [])

  useEffect(() => {
    if (urlParams.id && !broadcastRef.current) {
      const bc = new BroadcastChannel(urlParams.id)

      bc.postMessage({ type: BroadcastMessage[`open${capitalize(urlParams.interface)}`] })

      bc.onmessage = (event: MessageEvent) => {
        if (event.data.type === BroadcastMessage[`close${capitalize(urlParams.interface)}`]) {
          window.close()
        }
        if (event.data.type === BroadcastMessage.updateName) {
          dispatch(setSelectedSimulation({
            ...selectedSimulation,
            name: event.data.message,
          }))
        }
        if (event.data.type === BroadcastMessage.updateSoundState && isTcoWindow()) {
          setIsMuted(event.data.muted)
        }
      }

      broadcastRef.current = bc

      dispatch(getSimulation(urlParams.id))
    }

    if (isOldSimulation(urlParams) || !isTcoWindow()) {
      window.addEventListener('beforeunload', sendCloseMessage)
    } else {
      window.addEventListener('beforeunload', confirmClose)
      window.addEventListener('unload', sendCloseMessage)
    }

    return () => {
      window.removeEventListener('beforeunload', confirmClose)
      window.removeEventListener('unload', sendCloseMessage)
      window.removeEventListener('beforeunload', sendCloseMessage)
    }
  }, [selectedSimulation])

  useEffect(() => {
    if (isOldSimulation(urlParams) || isTcoWindow()) return

    if (modalSignal.value) {
      window.addEventListener('beforeunload', sendCloseMessage)
      window.removeEventListener('beforeunload', confirmClose)
    } else {
      window.removeEventListener('beforeunload', sendCloseMessage)
      window.addEventListener('beforeunload', confirmClose)
    }
  }, [modalSignal.value])

  useEffect(() => {
    if (!stream || !svg) return
    Object.entries(stream).forEach(([key, value]) => {
      const element = document.getElementById(key)
      const properties = Object.keys(value)

      if (element) {
        properties.forEach(prop => {
          if (prop === 'innerHTML') {
            element.innerHTML = value[prop]
            return
          }
          element.setAttribute(prop, value[prop])
        })
      }
    })
  }, [stream, svg])

  const handleTptClick = (evt: MouseEvent<HTMLElement>) => {
    const { id } = (evt.target as SVGRectElement)
    if (id === 'ProtectionTestButton:1:led') {
      webSocket.current.send(JSON.stringify({
        messageType: SocketMessage.interaction,
        name: id,
        interaction: 'TOGGLE',
      }))
    }
  }

  const handleTcoClick = (evt: MouseEvent<HTMLElement>) => {
    setCoords({ x: 0, y: 0 })
    dispatch(setForm(undefined))
    const { id } = (evt.target as SVGRectElement)

    if (id && !id.includes('ZoneIntervalLight')) {
      setCoords({ x: evt.clientX, y: evt.clientY })
      webSocket?.current?.send(JSON.stringify({
        messageType: SocketMessage.click,
        names: [id],
      }))
    }
  }

  const clickEvent = () => {
    if (isOldSimulation(urlParams)) {
      return () => undefined
    }

    if (isDevEnv()) {
      return isTcoWindow() ? handleTcoClick : handleTptClick
    }

    return isTcoWindow() ? () => undefined : handleTptClick
  }

  const click = clickEvent()

  return (
    <>
      <SvgWrapper coords={coords} svg={svg} handleClick={click} />
      <SimulationStream type={`${urlParams.interface}-svg`} />

      {isTcoWindow()
      && (
      <>
        <AudioPlayer beep={tcoBeep} canPlay={userHasInteracted} mute={isMuted} />
        <AudioPlayer beep={altBeep} canPlay={userHasInteracted} mute={isMuted} />
      </>
      )}
    </>
  )
}
