import { signal } from '@preact/signals-react'
import {
  createContext,
  MouseEvent,
  ReactNode, useEffect, useRef, useState,
} from 'react'

import { useAppSelector } from 'hooks'
import { RootState } from 'reducers/store'
import { SocketMessage, TrainManagerSocketMessage } from 'types/websocket'
import { PANEL_OPTIONS } from 'utils/panel'
import './PanelsManager.scss'

/**
 * Signal used to communicate with right panel
 * which is responsible for displaying/hiding the content
*/
export const PanelSignal = signal<React.ReactNode>(undefined)
export const PanelRefContext = createContext(null)
// todo add transition on open/close
export default function PanelsManager() {
  const managerRef = useRef<HTMLDivElement>(null)
  const [displayed, setDisplayed] = useState(false)
  const [activePanel, setActivePanel] = useState(undefined)
  const { trainWebsocket } = useAppSelector((state: RootState) => state.snci)

  const handleClose = (evt: MouseEvent) => {
    // we do it this way so we dont stop propagation and allow the snci to keep focus
    const clickedBtn = (evt.target as HTMLElement).closest('.sidebar > button')
    if (clickedBtn?.classList.contains('selected') || !clickedBtn) {
      PanelSignal.value = undefined
      setActivePanel(undefined)
      setDisplayed(false)

      // todo fix, it shouldnt be here
      trainWebsocket?.current?.send(JSON.stringify({
        messageType: SocketMessage.trainManager,
        consumerSpecific: true,
        state: TrainManagerSocketMessage.empty,
      }))
    }
  }

  useEffect(() => {
    if (!PanelSignal.value) {
      setDisplayed(false)
      setActivePanel(undefined)
      return
    }
    setDisplayed(true)
    managerRef.current.scrollTo(0, 0)
  }, [PanelSignal.value])

  useEffect(() => {
    if (!managerRef.current) return undefined

    const handleTransitionEnd = () => {
      if (displayed) return
      PanelSignal.value = undefined
    }

    managerRef.current.addEventListener('transitionend', handleTransitionEnd)
    return () => managerRef?.current?.removeEventListener('transitionend', handleTransitionEnd)
  }, [managerRef.current, displayed])

  const openPanel = (component: ReactNode, index: number) => (event: MouseEvent<HTMLButtonElement>) => {
    if (PanelSignal.value === component || index === activePanel) {
      handleClose(event)
      return
    }
    setActivePanel(index)
    PanelSignal.value = component
  }

  return (
    <div className="toolbar-panel">
      <div className="sidebar" onClick={evt => handleClose(evt)}>
        {PANEL_OPTIONS.map((option, index) => (
          <button
            disabled={trainWebsocket?.current?.readyState !== 1 && !displayed}
            className={`${activePanel === index ? 'selected' : ''}`}
            key={option.name}
            type="button"
            onClick={openPanel(option.component, index)}
          >
            {option.icon}
          </button>
        ))}

      </div>
      <PanelRefContext.Provider value={managerRef}>
        <div ref={managerRef} className={`panels-manager hide-scroll${displayed ? ' displayed' : ' hidden'}`}>
          {PanelSignal.value}
        </div>
      </PanelRefContext.Provider>
    </div>
  )
}
