import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/error.svg'
import terms from 'assets/localize/terms'
import SimpleButton, { ButtonPadding, ButtonStyle } from 'components/button/SimpleButton'
import { modalSignal } from 'components/modals/wrapper'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'reducers/store'
import { TrainerSocketMessage } from 'types/websocket'
import { useAppSelector } from 'utils'
import './ClosePopup.scss'

export default function ClosePopup() {
  const { trainerWebSocket } = useAppSelector((state: RootState) => state.trainer)
  const navigate = useNavigate()

  const handleClose = () => {
    modalSignal.value = undefined
    trainerWebSocket?.current?.send(JSON.stringify({
      messageType: TrainerSocketMessage.closeDashboard,
    }))
    navigate('/')
  }

  return (
    <div className="close-popup">
      <div className="header">
        <div className="title">{terms.Trainer.closeRoom}</div>
        <button type="button">
          <CloseIcon />
        </button>
      </div>

      <div className="warning-action">
        <InfoIcon />
        <div className="body">{terms.Trainer.noReturnAction}</div>
      </div>

      <div className="action-buttons">
        <SimpleButton
          style={ButtonStyle.roundedWhite}
          padding={ButtonPadding.xl}
          onClick={() => { modalSignal.value = undefined }}
          text={terms.Common.cancel}
        />

        <SimpleButton
          disabled={!trainerWebSocket?.current}
          style={ButtonStyle.purple}
          padding={ButtonPadding.xl}
          onClick={handleClose}
          text="Clôturer le TP"
        />

      </div>
    </div>
  )
}
