import { useState } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { setSnackbar, SnackbarSeverity } from 'reducers/app'
import { store, type AppDispatch, type RootState } from 'reducers/store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const useCopyToClipboard = (timeout = 2000) => {
  const [copied, setCopied] = useState(false)

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)
      setCopied(true)
      store.dispatch(setSnackbar({ message: 'Texte copié !', severity: SnackbarSeverity.SUCCESS }))
      setTimeout(() => setCopied(false), timeout)
    } catch (err) {
      setCopied(false)
      store.dispatch(setSnackbar({ message: 'Erreur lors de la copie', severity: SnackbarSeverity.ERROR }))
    }
  }

  return { copied, copyToClipboard }
}

export default useCopyToClipboard
