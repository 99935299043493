import { get, post } from '@osrdata/app_core/dist/requests'
import { createApiThunk } from 'utils'

import { setDashboard, setSimulation } from './slices'

const createPractical = (scheduleId: string, name: string) => createApiThunk(
  'trainer/createPractical',
  () => post('/masters/dashboards', { name, scheduleId }),
  setDashboard,
)

const getPracticalDashboard = (id: string) => createApiThunk(
  'trainer/getPractical',
  () => get(`/masters/dashboards/${id}`),
  setDashboard,
)

const createPracticalSimulation = (code: string) => createApiThunk(
  'trainer/getPractical',
  () => post(
    '/masters/simulations-dashboard/',
    { name: Math.random().toString(36).substring(2, 8), dashboardCode: code },
  ),
  setSimulation,
)

export {
  createPractical, createPracticalSimulation, getPracticalDashboard,
}
