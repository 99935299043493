import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg'
import { ReactComponent as StopIcon } from 'assets/icons/stop-alt.svg'
import { ReactComponent as TriangleIcon } from 'assets/icons/triangle-arrow.svg'
import terms from 'assets/localize/terms'
import { CustomAutocomplete, PanelRefContext, PanelSignal } from 'components'
import SimpleButton, { ButtonPadding, ButtonRadius, ButtonStyle } from 'components/button/SimpleButton'
import { trainDetailsNullRepresentation } from 'features/snci/services'
import { formatToReadablePk, ForwardState, getTrainName } from 'features/snci/services/snci'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useContext, useEffect, useState } from 'react'
import { NetworksState } from 'reducers/networks/slices'
import { setShouldCloseManeuver } from 'reducers/snci/slices'
import { TrainManagerSocketMessage } from 'types/websocket'
import TrainDetails from '../TrainDetails'
import './ManeuverMode.scss'

type Props = {
  id: string;
}

export default function ManeuverMode({ id }: Props) {
  const dispatch = useAppDispatch()
  const sharedPanelRef = useContext(PanelRefContext)
  const {
    trainWebsocket, maneuver, trainDetails, shouldCloseManeuver,
  } = useAppSelector(state => state.snci)
  const { objects: networkObjects } = useAppSelector(state => state.networks as NetworksState)
  const [crossingSignal, setCrossingSignal] = useState('')
  const [showLeavingScreen, setShowLeavingScreen] = useState(false)

  const sendUturn = () => {
    trainWebsocket?.current?.send(
      JSON.stringify({
        messageType: TrainManagerSocketMessage.trainUturn,
        trainId: id,
      }),
    )
  }

  const sendStateChange = () => {
    const message = maneuver?.forwardButtonState === ForwardState.move
      ? TrainManagerSocketMessage.trainMove : TrainManagerSocketMessage.trainStop
    trainWebsocket?.current?.send(
      JSON.stringify({
        messageType: message,
        trainId: id,
      }),
    )
  }

  const sendCrossingAuthorization = () => {
    trainWebsocket?.current?.send(
      JSON.stringify({
        messageType: TrainManagerSocketMessage.crossingAuthorization,
        trainId: id,
        onSight: false,
        firstSignalName: crossingSignal,
      }),
    )
    setCrossingSignal('')
  }

  const leaveManeuverMode = () => {
    sharedPanelRef.current.scrollTo(0, 0)
    setShowLeavingScreen(true)
  }

  const stayOnManeuverMode = () => {
    sharedPanelRef.current.scrollTo(0, 0)
    setShowLeavingScreen(false)
  }

  const sendLeaveManeuveMode = () => {
    trainWebsocket?.current?.send(
      JSON.stringify({
        messageType: TrainManagerSocketMessage.exitManeuverMode,
        trainId: id,
      }),
    )

    PanelSignal.value = <TrainDetails id={id} />
  }

  useEffect(() => {
    if (shouldCloseManeuver) {
      dispatch(setShouldCloseManeuver(false))
      sendLeaveManeuveMode()
    }
  }, [shouldCloseManeuver])

  return (
    <div className="maneuver-mode">
      <div className="main">
        <div className="title">
          <h3 className="mode">{`${terms.Snci.Panel.ManeuverMode.title}`}</h3>
          <h5 className="index">
            {getTrainName(maneuver || trainDetails)}
          </h5>

        </div>

        <div className="warning">
          <div />
          {showLeavingScreen ? (
            <div className="information">
              <div className="message bold">{terms.Snci.Panel.ManeuverMode.leavingWarning}</div>
              <div className="message">{terms.Snci.Panel.ManeuverMode.directionWarning}</div>
            </div>
          ) : (
            <div className="message">{terms.Snci.Panel.featureWarning}</div>
          )}

        </div>

        {!showLeavingScreen && (
        <div className="info">

          <div className="field-container">
            <div className="label">{terms.Snci.Panel.TrainDetails.speed}</div>
            <div className="value">{trainDetails.speed ?? trainDetailsNullRepresentation}</div>
          </div>

          <div className="field-container">
            <div className="label">{terms.Snci.Panel.TrainDetails.pk}</div>
            <div className="value">{formatToReadablePk((trainDetails.pk || trainDetailsNullRepresentation))}</div>
          </div>

        </div>

        )}

        <div className="info">
          <div className="field-container">
            <div className="label">{terms.Snci.Panel.TrainDetails.direction}</div>
            <div className="value bold">{maneuver?.direction}</div>
          </div>

          <SimpleButton
            disabled={!maneuver?.uturnVisibility}
            style={ButtonStyle.purple}
            padding={ButtonPadding.small}
            text={terms.Common.change}
            startIcon={<RefreshIcon />}
            onClick={sendUturn}
          />
        </div>

        {!showLeavingScreen && (
        <div className="info">
          <div className="field-container">
            <div className="label">{terms.Snci.Panel.ManeuverMode.state}</div>
            <div className="value bold">{maneuver?.state}</div>
          </div>

          <SimpleButton
            padding={ButtonPadding.small}
            disabled={maneuver?.forwardButtonState === ForwardState.unavailable}
            style={ButtonStyle.purple}
            text={maneuver?.forwardButtonState === ForwardState.move ? terms.Snci.Panel.ManeuverMode.advance
              : terms.Snci.Panel.ManeuverMode.stop}
            startIcon={maneuver?.forwardButtonState === ForwardState.move ? <TriangleIcon /> : <StopIcon />}
            onClick={sendStateChange}
          />
        </div>
        )}

        <div className="separator" />
      </div>

      {showLeavingScreen ? (
        <div className="leave-actions">
          <SimpleButton
            disabled={!maneuver?.maneuverExitVisibility}
            style={ButtonStyle.lightPurple}
            text={terms.Snci.Panel.ManeuverMode.goBack}
            padding={ButtonPadding.large}
            onClick={stayOnManeuverMode}
            radius={ButtonRadius.medium}
          />

          <SimpleButton
            className="leave-mode"
            disabled={!maneuver?.maneuverExitVisibility}
            style={ButtonStyle.roundedPurple}
            text={terms.Common.leave}
            padding={ButtonPadding.large}
            onClick={sendLeaveManeuveMode}
            radius={ButtonRadius.medium}
          />
        </div>

      ) : (
        <div className="secondary-actions">
          <div>
            {maneuver?.crossingAuthorizationVisibility && (
            <>
              <h4>{terms.Snci.Panel.Communication.crossingAuthorization}</h4>
              <div className="crossing">

                <CustomAutocomplete
                  objects={networkObjects.signals.map(obj => ({ value: obj, label: obj }))}
                  value={crossingSignal}
                  placeholder={terms.Snci.Panel.ManeuverMode.signalName}
                  onValueChange={setCrossingSignal}
                />

                <SimpleButton
                  className="send"
                  padding={ButtonPadding.small}
                  style={ButtonStyle.roundedPurple}
                  text={terms.Common.give}
                  disabled={!crossingSignal}
                  onClick={sendCrossingAuthorization}
                  radius={ButtonRadius.medium}
                />
              </div>
            </>
            )}
          </div>

          <SimpleButton
            className="leave-mode"
            disabled={!maneuver?.maneuverExitVisibility}
            style={ButtonStyle.lightPurple}
            padding={ButtonPadding.large}
            text={terms.Snci.Panel.ManeuverMode.leaveMode}
            onClick={leaveManeuverMode}
          />
        </div>
      )}
    </div>
  )
}
