import CircleIcon from '@mui/icons-material/Circle'
import {
  Checkbox,
  Table,
  TableBody, TableCell, TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material/'
import { signal } from '@preact/signals-react'
import terms from 'assets/localize/terms'
import SimpleButton from 'components/button/SimpleButton'
import { useAppDispatch, useAppSelector } from 'hooks'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { setSelectedSimulation } from 'reducers/simulations/slices'
import { createSimulationReplay } from 'reducers/simulations/thunks'
import { Simulation } from 'reducers/types'
import { CustomRoutes } from 'services/route'
import { criticityColor } from 'utils'
import { COLUMNS } from './header'
import './style.scss'

type Props = {
  onClick: (sim: Simulation) => void;
  onSelectSimulations: (sims: string[]) => void;
  order: string;
  setOrder: React.Dispatch<React.SetStateAction<string>>;
  orderBy: string;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  pageSize: number;
  setPageSize: (size: number) => void;
  page: number;
  setPage: (page: number) => void;
  allSelected: boolean;
};

export const resetSimulationsSignal = signal<boolean>(false)

export default function BasicTable({
  onClick, order, setOrder, orderBy, setOrderBy, onSelectSimulations,
  pageSize, setPageSize, page, setPage, allSelected,
}: Props) {
  const dispatch = useAppDispatch()
  const { simulationsList, filters } = useAppSelector(state => state.simulations)
  const { usersInfo } = useAppSelector(state => state.userinfo)
  const [selected, setSelected] = useState<readonly string[]>([])

  // todo move to parent
  useEffect(() => {
    setSelected([])
    onSelectSimulations([])
  }, [filters])

  useEffect(() => {
    if (resetSimulationsSignal.value) {
      resetSimulationsSignal.value = false
      setSelected([])
    }
  }, [resetSimulationsSignal.value])

  useEffect(() => {
    if (allSelected) {
      const newSelected = [
        ...simulationsList.results.map(n => n.id),
      ]
      setSelected(newSelected)
    }
  }, [allSelected])

  useEffect(() => {
    if (allSelected) {
      setSelected([])
    }
  }, [page])

  const sortby = (query: string) => {
    setOrder(order === '-' ? '' : '-')
    setOrderBy(query)
    setPage(0)
  }

  const handleClick = (id: string) => {
    const selectedIndex = selected.indexOf(id)
    const newSelected = [...selected]

    if (selectedIndex === -1) {
      newSelected.push(id)
    } else {
      newSelected.splice(selectedIndex, 1)
    }
    setSelected(newSelected)
    onSelectSimulations(newSelected)
  }

  const isSelected = (id: string) => selected.indexOf(id) !== -1

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = [
        ...simulationsList.results.map(n => n.id),
      ]

      setSelected(newSelected)
      onSelectSimulations(newSelected)
      return
    }
    const simulations = selected.filter(
      select => !simulationsList.results.some(elem => elem.id === select),
    )
    setSelected(simulations)
    onSelectSimulations(simulations)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getUsername = (id: string) => usersInfo.find(user => user.id === id)?.username || ''

  const createReplay = (row: Simulation) => {
    dispatch(createSimulationReplay(row.id, moment().format('YYMMDD-HHmm')))
      .unwrap().then(res => {
        window.open(`${window.location.origin}${CustomRoutes.experimenter}/${(res.payload as Simulation).id}`)
        dispatch(setSelectedSimulation(undefined))
      })
  }
  return (
    <>
      <TableContainer className="table-container">
        <Table stickyHeader aria-label="history table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  aria-label="table-select-all"
                  color="primary"
                  checked={
                    selected.length > 0
                    && simulationsList.results.every(simulation => selected.includes(simulation.id))
                  }
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              {COLUMNS.map(head => (
                <TableCell
                  key={head.label}
                  sx={{ minWidth: head.width }}
                  width={head.width}
                >
                  <TableSortLabel
                    direction={order === '-' ? 'desc' : 'asc'}
                    active={orderBy === head.sortBy}
                    onClick={() => sortby(head.sortBy)}
                  >
                    <strong>{head.label}</strong>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {simulationsList.results?.map(row => (
              <TableRow
                hover
                key={row.incrementalNumber}
                role="button"
                sx={{ 'td, th': { border: 0 }, cursor: 'pointer' }}
              >
                <TableCell padding="checkbox" onClick={() => handleClick(row.id)}>
                  <Checkbox color="primary" checked={isSelected(row.id)} />
                </TableCell>
                <TableCell>{row.incrementalNumber}</TableCell>
                <TableCell style={{ textTransform: 'uppercase' }}>{getUsername(row.userId)}</TableCell>
                <TableCell aria-label="table-name" onClick={() => onClick(row)}>{row.name}</TableCell>
                <TableCell>
                  <CircleIcon
                    style={{ color: criticityColor(row?.severity) }}
                  />
                </TableCell>
                <TableCell>{row?.status}</TableCell>
                <TableCell>{moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                <TableCell>{row.scenario.versionName}</TableCell>
                <TableCell>
                  <SimpleButton onClick={() => createReplay(row)} text={terms.History.replay} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[
          { value: 20, label: '20' },
          { value: 50, label: '50' },
          { value: 100, label: '100' },
        ]}
        count={simulationsList?.count}
        rowsPerPage={pageSize}
        page={page}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur un total de ${count}`}
        labelRowsPerPage={<span>Simulations par page</span>}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}
