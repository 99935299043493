export const findOccurrences = (reference: string, search: string): { segment: string, match: boolean }[] => {
  const result: { segment: string, match: boolean }[] = []
  let i = 0

  if (!search) return [{ segment: reference, match: false }]

  // eslint-disable-next-line no-plusplus
  for (let j = 0; j < reference.length; j++) {
    if (reference.substring(j, j + search.length).toLowerCase() === search.toLowerCase()) {
      if (j > i) {
        result.push({ segment: reference.substring(i, j), match: false })
      }

      result.push({ segment: reference.substring(j, j + search.length), match: true })

      i = j + search.length
      j = i - 1
    }
  }

  if (i < reference.length) {
    result.push({ segment: reference.substring(i), match: false })
  }

  return result
}
